import * as easings from 'd3-ease';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useSpring, useSprings } from 'react-spring';
import { updateRenderState, updateScore } from '../../actions';
import {
  getPotMoveParams,
  initInvisible,
  initVisible,
  toInvisible,
  toVisible,
} from '../../animations';
import { plantLottie, starsLottie, windowPageAssets } from '../../assets';
import { language } from '../../locale';
import score from '../../score';
import { loadLottie } from '../../utils/lottie';
import './WindowPage.css';

const { windowBg, background, moon, stars, potShadow, wall } = windowPageAssets;

const WindowPage = ({ page }) => {
  const { height: vh } = useSelector((state) => state.viewportReducer);
  const dispatch = useDispatch();
  const plantRef = useRef(null);
  const starsLottieContainer = useRef(null);
  const [starsAnim, setStarsAnim] = useState(null);
  const { audioElements, currentAudioIndex } = useSelector(
    (state) => state.audioReducer
  );
  const { isPosterReady } = useSelector((state) => state.posterReducer);
  const [windowSpring, setWindowSpring] = useSpring(initVisible);
  const [situationSpring, setSituationSpring] = useSpring(initInvisible);
  const [tapNoticeSpring, setTapNoticeSpring] = useSpring(initInvisible);
  const [questionSpring, setQuestionSpring] = useSpring(initInvisible);
  const [plantSpring, setPlantSpring] = useSpring(() => ({
    transform: 'translate(0%, 0px) scale(0.6)',
  }));
  const [moonSpring, setMoonSpring] = useSpring(() => ({
    transform: 'translateY(0px)',
  }));
  const [potShadowSpring, setPotShadowSpring] = useSpring(() => ({
    opacity: 0,
    transform: `translateY(0px)`,
  }));
  const [wallSpring, setWallSpring] = useSpring(() => ({
    transform: `translateY(0px)`,
  }));
  const [pageSpring, setPageSpring] = useSpring(() => ({
    opacity: 1,
    filter: 'blur(0px)',
  }));
  const [optionSprings, setOptionSprings] = useSprings(4, initVisible);
  const [endingTextSpring, setEndingTextSpring] = useSpring(initInvisible);
  const [endingRemindSpring, setEndingRemindSpring] = useSpring(initInvisible);
  const [renderEndingRemind, setRenderEndingRemind] = useState(true);
  const [conceptSpring, setConceptSpring] = useSpring(initInvisible);
  const [loadingTextSpring, setLoadingTextSpring] = useSpring(initInvisible);

  const [showStars, setShowStars] = useState(true);
  const [starsSpring, setStarsSpring] = useSpring(() => ({ opacity: 1 }));

  const canClickSituation = useRef(false);
  const canClickOption = useRef(false);
  const canGoResult = useRef(false);

  const [continueLoopSpring, setContinueLoopSpring] = useSpring(() => ({
    opacity: 1,
    to: async (next) => {
      while (1) {
        await next({ opacity: 0.3 });
        await next({ opacity: 1 });
      }
    },
    config: { duration: 1200 },
  }));

  const toPoster = () => {
    window.removeEventListener('click', toPoster);
    setPageSpring({
      opacity: 0,
      onRest: () => {
        dispatch(updateRenderState({ page: 19 }));
      },
    });
  };

  const toTheSkyFullOfStars = () => {
    setPageSpring({
      filter: 'blur(0px)',
      onRest: () => {
        setPotShadowSpring({
          transform: `translateY(${vh}px)`,
          onRest: null,
          config: { duration: 2000, easing: (t) => easings.easeQuadIn(t) },
        });
        setMoonSpring({
          transform: `translateY(${vh}px)`,
          config: { duration: 2000, easing: (t) => easings.easeQuadIn(t) },
        });
        setPlantSpring({
          transform: `translate(50%, ${vh + 100}px) scale(0.9)`,
          config: { duration: 2000, easing: (t) => easings.easeQuadIn(t) },
          onRest: () => {
            plantAnim.destroy();
          },
        });
        setWallSpring({
          transform: `translate(${vh}px)`,
          config: { duration: 2000, easing: (t) => easings.easeQuadIn(t) },
          onRest: () => {
            starsAnim.setSubframe(false);
            starsAnim.play();
            setTimeout(() => {
              setLoadingTextSpring({
                ...toVisible,
                onRest: () => {
                  setConceptSpring({
                    ...toVisible,
                    onRest: () => {
                      dispatch(updateRenderState({ page: 13 }));
                    },
                  });
                },
              });
            }, 800);
          },
        });
      },
    });
  };

  useEffect(() => {
    if (isPosterReady) {
      setUnreadySpring({
        ...toInvisible,
        onRest: () => {
          setReadySpring(toVisible);
        },
      });
      window.addEventListener('click', toPoster);
    }
  }, [isPosterReady]);

  useEffect(() => {
    setStarsAnim(
      loadLottie(starsLottieContainer.current, starsLottie, true, false)
    );
  }, []);

  useEffect(() => {
    page === 18 && toTheSkyFullOfStars();
  }, [page]);

  const handleSituationClick = () => {
    if (!canClickSituation.current) return;
    canClickSituation.current = false;
    window.removeEventListener('click', handleSituationClick);
    setSituationSpring({
      ...toInvisible,
      onRest: () => {
        setQuestionSpring({
          delay: 1000,
          ...toVisible,
          onRest: () => {
            canClickOption.current = true;
          },
        });
      },
    });
  };

  useEffect(() => {
    page === 12 &&
      setSituationSpring({
        ...toVisible,
        onRest: () => {
          setTapNoticeSpring({
            ...toVisible,
            onRest: () => {
              canClickSituation.current = true;
              window.addEventListener('click', handleSituationClick);
              setTapNoticeSpring({
                to: async (next) => {
                  while (1) {
                    await next({ opacity: 0.3 });
                    await next({ opacity: 1 });
                  }
                },
                config: { duration: 1200 },
              });
            },
          });
        },
      });
  }, [page]);

  const showNamePage = () => {
    if (!canGoResult.current) return;
    canGoResult.current = false;
    setEndingTextSpring({
      ...toInvisible,
      onRest: null,
    });
    setRenderEndingRemind(false);
    setPageSpring({
      opacity: 1,
      filter: 'blur(5px)',
      onRest: () => {
        setShowStars(false);
        setStarsSpring({ opacity: 0, config: { duration: 1000 } });
        dispatch(updateRenderState({ page: 17 }));
      },
    });
  };

  const handleOptionClick = (index) => () => {
    if (!canClickOption.current) return;
    canClickOption.current = false;
    if (audioElements[currentAudioIndex])
      audioElements[currentAudioIndex].pause();
    audioElements[6].play();
    dispatch(updateScore(score.q8[index]));

    // options fadeout -> delay 1000 -> question fadeout -> window fadeout, pot move down -> pot shadow fade in ->
    // delay 1000 -> ending text, remind fadein -> ready to result

    setOptionSprings((springIndex) => {
      if (index !== springIndex) {
        return { to: { opacity: 0 } };
      }
    });
    setQuestionSpring({
      delay: 1000,
      ...toInvisible,
      onRest: () => {
        setWindowSpring(toInvisible);
        setPlantSpring({
          ...getPotMoveParams(vh),
          onRest: () => {
            setPotShadowSpring({
              ...toVisible,
              onRest: () => {
                setEndingTextSpring({
                  delay: 1000,
                  ...toVisible,
                  onRest: () => {
                    setEndingRemindSpring({
                      delay: 1000,
                      ...toVisible,
                      onRest: () => {
                        canGoResult.current = true;
                        setEndingRemindSpring({
                          onRest: null,
                          to: async (next) => {
                            while (1) {
                              await next({ opacity: 0.4 });
                              await next({ opacity: 1 });
                            }
                          },
                          config: { duration: 1200 },
                        });
                      },
                    });
                  },
                });
              },
            });
          },
        });
      },
    });
  };

  const [plantAnim, setPlantAnim] = useState(null);

  useEffect(() => {
    setPlantAnim(loadLottie(plantRef.current, plantLottie, true, true));
  }, []);

  const [readySpring, setReadySpring] = useSpring(initInvisible);
  const [unreadySpring, setUnreadySpring] = useSpring(initVisible);

  return (
    <animated.div
      className="WindowPage"
      style={{ ...pageSpring, backgroundImage: `url(${background})` }}
      // onClick={toResult}
      onClick={showNamePage}
    >
      <animated.div className="WindowPage__moon" style={moonSpring}>
        <img src={moon} alt="" />
      </animated.div>
      <animated.div
        className={`Window__stars ${showStars ? '' : 'Window__stars--hidden'}`}
        style={starsSpring}
      >
        <img src={stars} alt="" />
      </animated.div>
      <div
        className="WindowPage__stars-lottie"
        ref={starsLottieContainer}
      ></div>
      <animated.div className="WindowPage__wall" style={wallSpring}>
        <img src={wall} alt="" />
      </animated.div>
      <animated.div
        className="WindowPage__plant"
        ref={plantRef}
        style={plantSpring}
      />
      <animated.div className="WindowPage__pot-shadow" style={potShadowSpring}>
        <img src={potShadow} alt="" />
      </animated.div>
      <animated.div
        className="WindowPage__window"
        style={{ ...windowSpring, backgroundImage: `url(${windowBg})` }}
      />

      <animated.div
        className={`WindowPage__situation ${language}`}
        style={situationSpring}
      >
        <FormattedMessage id="situation_hungry" values={{ br: <br /> }} />
        <animated.div
          className="WindowPage__tap-notice"
          style={tapNoticeSpring}
        >
          <FormattedMessage id="tap_to_continue" />
        </animated.div>
      </animated.div>

      <animated.div
        className={`WindowPage__concept ${language}`}
        style={conceptSpring}
      >
        <FormattedMessage
          id="core_concept_introduction_general"
          values={{
            br: <br />,
            icon: (
              <span className="app-icon">
                <div className="app-icon-inner">
                  <img src="https://www.forestapp.cc/img/icon.png" />
                </div>
              </span>
            ),
            bold: <span className="app-name--bold">Forest App</span>,
          }}
        />
      </animated.div>

      <animated.div
        className={`WindowPage__loading ${language}`}
        style={loadingTextSpring}
      >
        <animated.div className="WindowPage__loading-ready" style={readySpring}>
          <animated.div style={continueLoopSpring}>
            <FormattedMessage id="personal_flower_poster_complete_message" />
          </animated.div>
        </animated.div>
        <animated.div
          className="WindowPage__loading-unready"
          style={unreadySpring}
        >
          <FormattedMessage id="personal_flower_poster_loading" />
          <span className="dot--1"> .</span>
          <span className="dot--2">.</span>
          <span className="dot--3">.</span>
        </animated.div>
      </animated.div>

      <animated.div
        className="WindowPage__question-group"
        style={questionSpring}
      >
        <div className={`WindowPage__question ${language}`}>
          <FormattedMessage id="question_8" values={{ br: <br /> }} />
        </div>
        <div className="WindowPage__option-group">
          {optionSprings.map((props, index) => (
            <animated.div
              className={`WindowPage__option ${language}`}
              style={props}
              key={index}
              onClick={handleOptionClick(index)}
            >
              <FormattedMessage
                id={`question_8_option_${index + 1}`}
                values={{ br: <br /> }}
              />
            </animated.div>
          ))}
        </div>
      </animated.div>
      <animated.div
        className={`WindowPage__ending-text ${language}`}
        style={endingTextSpring}
      >
        <FormattedMessage id="ending_1_description" values={{ br: <br /> }} />
      </animated.div>
      {renderEndingRemind && (
        <animated.div
          className={`WindowPage__ending-remind ${language}`}
          style={endingRemindSpring}
        >
          <FormattedMessage id="tap_to_continue" />
        </animated.div>
      )}
    </animated.div>
  );
};

export default WindowPage;
