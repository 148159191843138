export const flattenDeep = (array) =>
  Array.isArray(array)
    ? array.reduce((flatted, item) => [...flatted, ...flattenDeep(item)], [])
    : [array];

export const clamp = (number, boundOne, boundTwo) => {
  if (!boundTwo) {
    return Math.max(number, boundOne) === boundOne ? number : boundOne;
  } else if (Math.min(number, boundOne) === number) {
    return boundOne;
  } else if (Math.max(number, boundTwo) === number) {
    return boundTwo;
  }

  return number;
};
