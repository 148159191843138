import * as easings from 'd3-ease';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import { updateRenderState } from '../../actions';
import {
  initInvisible,
  initVisible,
  toInvisible,
  toVisible,
} from '../../animations';
import {
  easterEggAssets,
  fireworksLottie,
  plantLottie,
  starsLottie,
  windowPageAssets,
} from '../../assets';
import { language } from '../../locale';
import { loadLottie } from '../../utils/lottie';
import './EasterEgg.css';

const { player, playerBg } = easterEggAssets;
const { background, stars, moon, potShadow, wall } = windowPageAssets;

function EasterEgg({ setScroll }) {
  const dispatch = useDispatch();
  const { height: vh } = useSelector((state) => state.viewportReducer);
  const { page } = useSelector((state) => state.renderStateReducer);
  const { isPosterReady } = useSelector((state) => state.posterReducer);
  const fireworksRef = useRef(null);
  const plantRef = useRef(null);
  const [fireworksAnim, setFireworksAnim] = useState(null);
  const [canGoResult, setCanGoResult] = useState(false);
  const starsLottieConatinerRef = useRef(null);
  const [starsAnim, setStarsAnim] = useState(null);
  const [showStars, setShowStars] = useState(true);
  const [textSpring, setTextSpring] = useSpring(initInvisible);
  const [pageSpring, setPageSpring] = useSpring(() => ({
    opacity: 1,
    filter: `blur(0px)`,
  }));
  const [plantSpring, setPlantSpring] = useSpring(() => ({
    transform: `translateY(0px)`,
  }));
  const [wallSpring, setWallSpring] = useSpring(() => ({
    transform: `translateY(0px)`,
  }));
  const [moonSpring, setMoonSpring] = useSpring(() => ({
    transform: 'translateY(0px)',
  }));
  const [potShadowSpring, setPotShadowSpring] = useSpring(() => ({
    transform: 'translateY(0px)',
  }));
  const [conceptSpring, setConceptSpring] = useSpring(initInvisible);
  const [loadingTextSpring, setLoadingTextSpring] = useSpring(initInvisible);

  const [remindSpring] = useSpring(() => ({
    opacity: 1,
    to: async (next) => {
      while (1) {
        await next({ opacity: 0.3 });
        await next({ opacity: 1 });
      }
    },
    config: { duration: 1200 },
  }));

  const [starsSpring, setStarsSpring] = useSpring(initVisible);

  const toPoster = () => {
    window.removeEventListener('click', toPoster);
    setPageSpring({
      opacity: 0,
      onRest: () => {
        dispatch(updateRenderState({ page: 19 }));
      },
    });
  };

  useEffect(() => {
    if (isPosterReady) {
      setUnreadySpring({
        ...toInvisible,
        onRest: () => {
          setReadySpring(toVisible);
        },
      });
      window.addEventListener('click', toPoster);
    }
  }, [isPosterReady]);

  const toTheSkyFullOfStars = () => {
    setPageSpring({
      filter: `blur(0px)`,
      onRest: () => {
        setPotShadowSpring({
          transform: `translateY(${vh}px)`,
          config: { duration: 2000, easing: (t) => easings.easeQuadIn(t) },
        });
        setMoonSpring({
          transform: `translateY(${vh}px)`,
          config: { duration: 2000, easing: (t) => easings.easeQuadIn(t) },
        });
        setPlantSpring({
          transform: `translateY(${vh}px)`,
          config: { duration: 2000, easing: (t) => easings.easeQuadIn(t) },
        });
        setWallSpring({
          transform: `translateY(${vh}px)`,
          config: { duration: 2000, easing: (t) => easings.easeQuadIn(t) },
          onRest: () => {
            starsAnim.play();
            setTimeout(() => {
              setLoadingTextSpring({
                ...toVisible,
                onRest: () => {
                  setConceptSpring({
                    ...toVisible,
                    onRest: () => {
                      dispatch(updateRenderState({ page: 13 }));
                    },
                  });
                },
              });
            }, 800);
          },
        });
      },
    });
  };

  const showText = () => {
    setTextSpring({
      ...toVisible,
      config: { duration: 1000 },
      onRest: () => {
        setCanGoResult(true);
      },
    });
  };

  const playFireworks = () => {
    fireworksAnim.play();
    setTimeout(() => {
      showText();
    }, 3500);
  };

  useEffect(() => {
    page === 16 && playFireworks();
    page === 18 && toTheSkyFullOfStars();
  }, [page]);

  const toResult = () => {
    if (!canGoResult) return;
    setCanGoResult(false);
    setTextSpring({
      ...toInvisible,
      onRest: null,
    });
    setPageSpring({
      filter: `blur(5px)`,
      onRest: () => {
        setShowStars(false);
        setStarsSpring({ opacity: 0, config: { duration: 1000 } });
        dispatch(updateRenderState({ page: 17 }));
      },
    });
  };

  useEffect(() => {
    setScroll({ immediate: true, scroll: vh + 1000, onRest: null });
    const fireworksAnim = loadLottie(
      fireworksRef.current,
      fireworksLottie,
      false
    );
    loadLottie(plantRef.current, plantLottie, true, true);
    setFireworksAnim(fireworksAnim);
    setStarsAnim(
      loadLottie(starsLottieConatinerRef.current, starsLottie, true, false)
    );
  }, []);

  const [readySpring, setReadySpring] = useSpring(initInvisible);
  const [unreadySpring, setUnreadySpring] = useSpring(initVisible);

  return (
    <animated.div className="EasterEgg" onClick={toResult} style={pageSpring}>
      <div
        className="EasterEgg__Page1"
        style={{ backgroundImage: `url(${background})` }}
      >
        <animated.div className="EasterEgg__moon" style={moonSpring}>
          <img src={moon} alt="" />
        </animated.div>
        <div
          className="EasterEgg__lottie-stars"
          ref={starsLottieConatinerRef}
        />
        <animated.div
          className={`EasterEgg__stars ${showStars ? '' : 'hidden'}`}
          style={starsSpring}
        >
          <img src={stars} alt="" />
        </animated.div>
        <animated.div
          className={`EasterEgg__concept ${language}`}
          style={conceptSpring}
        >
          <FormattedMessage
            id="core_concept_introduction_special"
            values={{
              br: <br />,
              icon: (
                <span className="app-icon">
                  <div className="app-icon-inner">
                    <img src="https://www.forestapp.cc/img/icon.png" alt="" />
                  </div>
                </span>
              ),
              bold: <span className="app-name--bold">Forest App</span>,
            }}
          />
        </animated.div>
        <animated.div
          className={`EasterEgg__loading-text ${language}`}
          style={loadingTextSpring}
        >
          <animated.div
            className="EasterEgg__loading-ready"
            style={readySpring}
          >
            <animated.div style={remindSpring}>
              <FormattedMessage id="personal_flower_poster_complete_message" />
            </animated.div>
          </animated.div>
          <animated.div
            className="EasterEgg__loading-unready"
            style={unreadySpring}
          >
            <FormattedMessage id="personal_flower_poster_loading" />
            <span className="dot--1"> .</span>
            <span className="dot--2">.</span>
            <span className="dot--3">.</span>
          </animated.div>
        </animated.div>
        <div className="EasterEgg__fireworks" ref={fireworksRef} />
        <animated.div
          className={`EasterEgg__description ${language}`}
          style={textSpring}
        >
          <FormattedMessage id="ending_2_description" values={{ br: <br /> }} />
        </animated.div>
        <animated.div className="EasterEgg__wall" style={wallSpring}>
          <img src={wall} alt="" />
        </animated.div>
        <animated.div
          className="EasterEgg__plant"
          ref={plantRef}
          style={plantSpring}
        />
        <animated.div className="EasterEgg__pot-shadow" style={potShadowSpring}>
          <img src={potShadow} alt="" />
        </animated.div>
        <animated.div
          className={`EasterEgg__remind ${language}`}
          style={textSpring}
        >
          <animated.div className="EasterEgg__remind-loop" style={remindSpring}>
            <FormattedMessage id="tap_to_continue" />
          </animated.div>
        </animated.div>
      </div>
      <div
        className="EasterEgg__Page2"
        style={{ backgroundImage: `url(${playerBg})` }}
      >
        <div className="EasterEgg__player">
          <img className="EasterEgg__player-image" src={player} alt="" />
        </div>
        <div className="EasterEgg__gradient" />
      </div>
    </animated.div>
  );
}

export default EasterEgg;
