import { useRef } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { forestAssets } from '../../assets';
import { ga } from '../../utils/analytics';
import { isHuaWei, isVivo, isXiaoMi } from '../../utils/device';
import { ver } from '../../version';
import Cards from '../Cards/Cards';
import './Forest.css';

const { logo, back, cards } = forestAssets;

function Forest({ setScroll, resultIndex, tree }) {
  const { isSpecialEnd } = useSelector((state) => state.renderStateReducer);
  const forestRef = useRef();
  const scrollBackToResult = () => {
    setScroll({
      immediate: false,
      scroll: 0,
    });
  };

  const { token } = useSelector((state) => state.redeemReducer);

  const toStore = () => {
    const toAppStore = () => {
      ga.toAppStore(resultIndex + 1);
      window.open(
        'https://apps.apple.com/app/apple-store/id866450515?pt=690486&ct=h5-personality-test&mt=8',
        '_blank'
      );
    };
    const toGooglePlay = () => {
      ga.toGooglePlay(resultIndex + 1);
      window.open(
        'https://play.google.com/store/apps/details?id=cc.forestapp&referrer=utm_source%3DH5_personality_test%26utm_campaign%3DH5_personality_test',
        '_blank'
      );
    };

    if (isAndroid) {
      toGooglePlay();
    } else if (isIOS) {
      toAppStore();
    } else {
      toGooglePlay();
    }
  };

  const toStoreCn = () => {
    const toAppStore = () => {
      ga.toAppStore(resultIndex + 1);
      window.open(
        'https://apps.apple.com/app/apple-store/id866450515?pt=690486&ct=h5-personality-test&mt=8',
        '_blank'
      );
    };
    const toHuaweiStore = () => {
      ga.toHuaweiStore(resultIndex + 1);
      window.open('https://appgallery5.huawei.com/#/app/C100382799', '_blank');
    };
    const toVivoStore = () => {
      ga.toVivoStore(resultIndex + 1);
      window.open('http://appdetailh5.vivo.com.cn/detail/53514', '_blank');
    };
    const toKuAnn = () => {
      ga.toCoolAnnStore(resultIndex + 1);
      window.open('https://www.coolapk.com/apk/cc.forestapp', '_blank');
    };
    const toXiaoMiStore = () => {
      ga.toXiaoMiStore(resultIndex + 1);
      window.open('http://app.mi.com/details?id=cc.forestapp', '_blank');
    };

    if (isIOS) {
      toAppStore();
    } else if (isVivo) {
      toVivoStore();
    } else if (isHuaWei) {
      toHuaweiStore();
    } else if (isXiaoMi) {
      toXiaoMiStore();
    } else {
      toKuAnn();
    }
  };

  // const [showDialog, setShowDialog] = useState(false);
  // const dialogTrans = useTransition(showDialog, null, initTransInvisible());

  // const handleRedeemClick = () => {
  //   if (isSpecialEnd) {
  //     return;
  //   }

  //   setShowDialog(true);
  // };

  const handleTryAgainClick = () => {
    ga.clickPlayAgain();
    window.open(
      `${
        ver === 'global'
          ? 'https://forest-mt.seekrtech.com/'
          : 'https://forest-mt.upwardsware.com/'
      }`,
      '_blank'
    );
  };

  const handleLogoClick = () => {
    ga.toForestLandingPage();
    window.open('https://www.forestapp.cc/', '_blank');
  };

  return (
    <div
      className={`Forest`}
      ref={forestRef}
      style={{
        background: `linear-gradient(var(--result-color-${
          resultIndex + 1
        }) 0%, #397f66 55%, #1f4d3d 100%)`,
      }}
      data-html2canvas-ignore
    >
      <div className="Forest__space" />
      <div className="Forest__content">
        <div className="Forest__to-result" onClick={scrollBackToResult}>
          <div>
            <FormattedMessage id="back_to_poster" />
          </div>
          <img src={back} alt="" />
        </div>
        <div className="Forest__logo">
          <img src={logo} alt="" onClick={handleLogoClick} />
        </div>
        <div className="Forest__slogan">
          <FormattedMessage id="forest_introduction_quote_1" />
        </div>
        <div className="Forest__cards-container">
          <Cards cards={cards} resultIndex={resultIndex} />
        </div>
        <div className="Forest__bottom">
          <div
            className="Forest__buttons"
          >
            <div
              className="Forest__store"
              onClick={ver === 'global' ? toStore : toStoreCn}
            >
              <FormattedMessage id="forest_introduction_button_store" />
            </div>
            {/* {isSpecialEnd === false && (
              <div className="Forest__redeem" onClick={handleRedeemClick}>
                <FormattedMessage id="forest_introduction_button_redeem" />
              </div>
            )} */}
          </div>
        </div>
        <div
          className={`Forest__try-again ${
            resultIndex === 16 ? 'Forest__try-again--hidden' : ''
          }`}
          onClick={handleTryAgainClick}
        >
          <FormattedMessage id="try_again" />
        </div>

        {/* {dialogTrans.map(
          ({ item, key, props }) =>
            item && (
              <Dialog
                resultIndex={resultIndex}
                style={props}
                key={key}
                closeDialog={() => {
                  setShowDialog(false);
                }}
                token={token}
                toStore={ver === 'global' ? toStore : toStoreCn}
                tree={tree}
              />
            )
        )} */}
      </div>
    </div>
  );
}

export default Forest;
