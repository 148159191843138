import axios from 'axios';

// const forestTreeGids = [
//   23,
//   9,
//   27,
//   8,
//   41,
//   19,
//   33,
//   25,
//   12,
//   34,
//   4,
//   20,
//   26,
//   21,
//   58,
//   45,
//   59,
// ];

export const getLottieJson = (url) => axios.get(`${url}`);

// export const createSession = () => axios.post('/quiz_sessions');

// export const getRedeemCode = (token, result_code) =>
//   axios.get(`/quiz_sessions/${token}/redeem_code`, {
//     params: {
//       tree_type_gid: forestTreeGids[result_code],
//       result_code: result_code,
//     },
//   });
