export const PRESSED_BTN_TEXT_COLORS = [
  '#D1D89D',
  '#F0EED0',
  '#F5D09A',
  '#EFC8B9',
  '#E4C281',
  '#DFEED1',
  '#F3C8B2',
  '#F1DC97',
  '#D0D2A8',
  '#F5C19E',
  '#FEEFC3',
  '#FEEEB1',
  '#FAC9B9',
  '#76689D',
  '#EBF5CC',
  '#C8DCE6',
];
