export const load = async (promises, setProgress, setIsLoading) => {
  const allProgress = () => {
    let progress = 0;

    return Promise.all(
      promises.map((promise) =>
        promise.then(() => {
          progress++;
          setProgress(parseInt((progress * 100) / promises.length));
        })
      )
    );
  };

  await allProgress(promises);
  setIsLoading(false);
};
