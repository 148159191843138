import React from 'react';

export default () => (
  <svg
    width="24"
    height="36"
    viewBox="0 0 24 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.7578 11.8473L5.43837 25.1892Z" fill="#A16464" />
    <path d="M18.7578 11.8473L5.43837 25.1892" stroke="#A16464" />
    <path d="M18.7569 25.1895L5.4375 11.8477Z" fill="#A16464" />
    <path d="M18.7569 25.1895L5.4375 11.8477" stroke="#A16464" />
  </svg>
);
