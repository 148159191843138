const event = ({ action, category, label }) => {
  try {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
    });
  } catch (error) {
    console.log('analytics are blocked by ad-blocker');
  }
};

export const ga = {
  enterApp: () => {
    event({
      action: 'enter app',
      category: 'enter',
    });
  },
  clickCover: () => {
    event({
      action: 'click cover start',
      category: 'click',
    });
  },
  enterEasterEgg: () => {
    event({
      action: 'enter easter egg',
      category: 'enter',
    });
  },
  enterPoster: (testingId) => {
    event({
      action: 'enter poster',
      category: 'enter',
      label: testingId,
    });
  },
  clickPal: (testingId) => {
    event({
      action: 'click pal',
      category: 'click',
      label: testingId,
    });
  },
  clickFb: () => {
    event({
      action: 'click fb',
      category: 'click',
    });
  },
  clickIg: () => {
    event({
      action: 'click ig',
      category: 'click',
    });
  },
  clickTwitter: () => {
    event({
      action: 'click twitter',
      category: 'click',
    });
  },
  clickWeibo: () => {
    event({
      action: 'click weibo',
      category: 'click',
    });
  },
  clickCta: (testingId) => {
    event({
      action: 'click cta',
      category: 'click',
      label: testingId,
    });
  },
  toAppStore: (flowerIndex) => {
    event({
      action: 'to app store',
      category: 'click',
      label: `flower: ${flowerIndex}`,
    });
  },
  toGooglePlay: (flowerIndex) => {
    event({
      action: 'to google play',
      category: 'click',
      label: `flower: ${flowerIndex}`,
    });
  },
  toHuaweiStore: (flowerIndex) => {
    event({
      action: 'to huawei store',
      category: 'click',
      label: `flower: ${flowerIndex}`,
    });
  },
  toCoolAnnStore: (flowerIndex) => {
    event({
      action: 'to cool ann store',
      category: 'click',
      label: `flower: ${flowerIndex}`,
    });
  },
  toXiaoMiStore: (flowerIndex) => {
    event({
      action: 'to xiao mi store',
      category: 'click',
      label: `flower: ${flowerIndex}`,
    });
  },
  toVivoStore: (flowerIndex) => {
    event({
      action: 'to vivo store',
      category: 'click',
      label: `flower: ${flowerIndex}`,
    });
  },
  enterForestPage: () => {
    event({
      action: 'enter forest page',
      category: 'enter',
    });
  },
  clickPlantPrompt: (label) => {
    event({
      action: 'click plant prompt',
      category: 'click',
      label,
    });
  },
  clickLogoToStore: (label) => {
    event({
      action: 'click_poster_logo_to_store',
      category: 'click',
      label,
    });
  },
  clickPlayAgain: () => {
    event({
      action: 'click play again',
      category: 'click',
    });
  },
  clickEnTwitterHash: () => {
    event({
      action: 'click_en_twitter_hashtag',
      category: 'click',
    });
  },
  clickEnInstagramHash: () => {
    event({
      action: 'click_en_ig_hashtag',
      category: 'click',
    });
  },
  clickWeiboHash: () => {
    event({
      action: 'click_weibo_hashtag',
      category: 'click',
    });
  },
  clickKoTwitterHash: () => {
    event({
      action: 'click_ko_twitter_hashtag',
      category: 'click',
    });
  },
  clickKoInstagram: () => {
    event({
      action: 'click_ko_ig_hashtag',
      category: 'click',
    });
  },
  clickFacebookHash: () => {
    event({
      action: 'click_fb_hashtag',
      category: 'click',
    });
  },
  toForestLandingPage: () => {
    event({
      action: 'to_forest_landing_page',
      category: 'click',
    });
  },
  clickBird: () => {
    event({
      action: 'click_bird',
      category: 'click',
    });
  },
  savePoster: () => {
    event({
      action: 'save_poster',
      category: 'long_press',
    });
  },
  jaShareOpenDialog: () => {
    event({
      action: 'ja_share_open_dialog',
      category: 'click',
    });
  },
  jaShareTweet: (contentId) => {
    event({
      action: 'ja_share_tweet',
      category: 'click',
      label: `content_${contentId}`,
    });
  },
  jaShareCopy: () => {
    event({
      action: 'ja_share_copy',
      category: 'click',
    });
  },
};
