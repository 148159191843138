import { Howl } from 'howler';

export const createAudio = (source) => {
  const audio = new Howl({
    src: [source],
    loop: true,
    autoplay: false,
  });
  return audio;
};
