import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useOrientation } from 'react-use';
import { setOrientation } from '../../actions';

const useGlobalOrientation = () => {
  const dispatch = useDispatch();
  const { angle } = useOrientation();

  useEffect(() => {
    dispatch(setOrientation(angle));
  }, [dispatch, angle]);
};

export default useGlobalOrientation;
