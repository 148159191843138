const INFJ = 'INFJ';
const ISTJ = 'ISTJ';
const ESTP = 'ESTP';
const ESFP = 'ESFP';
const ENTP = 'ENTP';
const ENTJ = 'ENTJ';
const INTJ = 'INTJ';
const INFP = 'INFP';
const ISFJ = 'ISFJ';
const ISTP = 'ISTP';
const INTP = 'INTP';
const ESFJ = 'ESFJ';
const ESTJ = 'ESTJ';
const ENFP = 'ENFP';
const ENFJ = 'ENFJ';
const ISFP = 'ISFP';

export default {
  q1: [INFJ, ISTJ, ESTP, ESFP],
  q3: [ENTP, ENTJ, INTJ, INFP],
  q4: [INFP, ENFJ, ESTP, ISFJ],
  q5: [ISTP, ISFJ, ESFP, ESTP],
  q6: [ENTJ, INTP, ESFJ, INFP],
  q7: [ESTJ, ISTP, ENFP, INTP],
  q8: [ESFJ, INFJ, INTJ, ENFP],
};

export const resultScore = [
  ESTJ,
  ESTP,
  ESFJ,
  ESFP,
  ENTJ,
  ENTP,
  ENFJ,
  ENFP,
  ISTJ,
  ISTP,
  ISFJ,
  ISFP,
  INTJ,
  INTP,
  INFJ,
  INFP,
];
