const copy = (textToCopy) => {
  const checkIsIos = () => {
    const index = navigator.userAgent.search(/ipad|iphone/i);

    return index > -1;
  };

  const createTextArea = () => {
    const textArea = document.createElement('textArea');
    textArea.style.position = 'absolute';
    textArea.style.opacity = 0;
    textArea.readOnly = true;
    textArea.contentEditable = true;
    textArea.value = textToCopy;
    document.body.appendChild(textArea);

    return textArea;
  };

  const selectText = (textArea) => {
    if (checkIsIos()) {
      const range = document.createRange();
      range.selectNodeContents(textArea);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, textToCopy.length);
    } else {
      textArea.select();
    }
  };

  const textArea = createTextArea();
  selectText(textArea);
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export default copy;
