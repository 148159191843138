import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import App from './components/App/App';
import './index.css';
import { locale, message } from './locale';
import store from './store';
import './utils/firebase';
import './utils/intl';

ReactDOM.render(
  <IntlProvider locale={locale} defaultLocale="en" messages={message}>
    <Provider store={store}>
      <App />
    </Provider>
  </IntlProvider>,
  document.getElementById('root')
);
