import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useSpring, useSprings } from 'react-spring';
import { updateRenderState, updateCurrentAudioIndex } from '../../actions';
import { FormattedMessage } from 'react-intl';
import { initInvisible, toVisible, initVisible } from '../../animations';
import { loadLottie } from '../../utils/lottie';
import { playerLottie } from '../../assets';
import './Page2.css';
import { page2_Assets } from '../../assets';
import { language } from '../../locale';

const { background } = page2_Assets;

const Page2 = () => {
  const dispatch = useDispatch();
  const { page } = useSelector((state) => state.renderStateReducer);
  const { audioElements } = useSelector((state) => state.audioReducer);
  const [descriptionSpring, setDescriptionSpring] = useSpring(initInvisible);
  const [questionSpring, setQuestionSpring] = useSpring(initInvisible);
  const [optionsSpring, setOptionsSpring] = useSpring(initInvisible);
  const [optionSprings, setOptionSprings] = useSprings(4, initVisible);
  const lottieRef = useRef();
  const [lottie, setLottie] = useState(null);
  const canClick = useRef(false);

  const optionsFadeOut = (originalIndex) => (index) =>
    index === originalIndex ? {} : { to: { opacity: 0 } };

  useEffect(() => {
    setLottie(loadLottie(lottieRef.current, playerLottie));
  }, []);

  useEffect(() => {
    page === 4 &&
      setDescriptionSpring({
        ...toVisible,
        onRest: () => {
          setQuestionSpring({
            ...toVisible,
            onRest: () => {
              setOptionsSpring({
                ...toVisible,
                onRest: () => {
                  canClick.current = true;
                },
              });
            },
          });
        },
      });
  }, [page]);

  const toNextPage = async () => {
    dispatch(updateRenderState({ page: 6 }));
  };

  const handleOptionClick = (index) => () => {
    if (!canClick.current) return;
    canClick.current = false;
    dispatch(updateRenderState({ page: 5 }));
    setOptionSprings(optionsFadeOut(index));
    lottie.play();

    audioElements[0].unload();
    audioElements[index + 1].play();
    dispatch(updateCurrentAudioIndex(index + 1));
    setTimeout(() => {
      toNextPage();
    }, 5000);
  };

  return (
    <animated.div
      className="Page2"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="Page2__question">
        <animated.span
          className={`Page2__description ${language}`}
          style={descriptionSpring}
        >
          <FormattedMessage
            id="question_2_description"
            values={{ br: <br /> }}
          />
        </animated.span>
        <animated.span
          className={`Page2__question-text ${language}`}
          style={questionSpring}
        >
          <FormattedMessage id="question_2" />
        </animated.span>
        <animated.div className="Page2__option-group" style={optionsSpring}>
          {optionSprings.map((props, index) => (
            <animated.div
              className={`option-normal ${language}`}
              style={props}
              key={index}
              onClick={handleOptionClick(index)}
            >
              <FormattedMessage
                id={`question_2_option_${index + 1}`}
                values={{ br: <br /> }}
              />
            </animated.div>
          ))}
        </animated.div>
      </div>

      <div className="Page2__lottie-container" ref={lottieRef} />
    </animated.div>
  );
};

export default Page2;
