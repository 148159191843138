export const disableScroll = () => {
  window.addEventListener(
    'touchmove',
    (e) => {
      e.preventDefault();
      if (e.scale !== 1) {
        e.preventDefault();
      }
    },
    { passive: false }
  );
};

export const disableDoubleTap = () => {
  let lastTouchEnd = 0;
  window.addEventListener(
    'touchstart',
    (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    },
    { passive: false }
  );

  window.addEventListener(
    'touchend',
    (event) => {
      const now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    { passive: false }
  );
};
