import { SET_ORIENTATION } from '../actions/types';

const initialState = {
  orientation: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ORIENTATION:
      return { ...state, orientation: action.payload };

    default:
      return state;
  }
};
