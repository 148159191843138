const appStoreLink =
  'https://apps.apple.com/app/apple-store/id866450515?pt=690486&ct=h5-personality-test&mt=8';
const googlePlayLink =
  'https://play.google.com/store/apps/details?id=cc.forestapp&referrer=utm_source%3DH5_personality_test%26utm_campaign%3DH5_personality_test';
const kuAnnStoreLink = 'https://www.coolapk.com/apk/cc.forestapp';
const xiaoMiStoreLink = 'http://app.mi.com/details?id=cc.forestapp';
const huaweiStoreLink = 'https://appgallery5.huawei.com/#/app/C100382799';
const vivoStoreLink = 'http://appdetailh5.vivo.com.cn/detail/53514';

const isTargetNewPage = true;

export const toStore = (testingId, logEvent) => {
  const userAgent = window.navigator.userAgent;
  const urlSearch = window.location.search;
  const urlSearchParams = new URLSearchParams(urlSearch);
  const isChina = urlSearchParams.get('utm_source') === 'cn';

  const goto = isTargetNewPage
    ? (url) => window.open(url, '_blank')
    : window.location.replace;

  const checkIsIos = () => userAgent.match(/iPhone|iPad/g) !== null;

  const checkIsXiaoMi = () => userAgent.match(/MI|Mi|Xiaomi|XiaoMi/g) !== null;

  const checkIsHuawei = () => userAgent.match(/huawei/gi) !== null;

  const checkIsVivo = () => userAgent.match(/vivo/gi) !== null;

  if (checkIsIos()) {
    logEvent(`${testingId}_app_store`);
    goto(appStoreLink);

    return;
  }

  if (isChina) {
    logEvent(`${testingId}_others`);

    if (checkIsHuawei()) {
      goto(huaweiStoreLink);

      return;
    }

    if (checkIsXiaoMi()) {
      goto(xiaoMiStoreLink);

      return;
    }

    if (checkIsVivo()) {
      goto(vivoStoreLink);

      return;
    }

    goto(kuAnnStoreLink);

    return;
  }

  logEvent(`${testingId}_google_play`);
  goto(googlePlayLink);
};
