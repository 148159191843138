import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './Audio.css';
import { audioSources, audioAssets } from '../../assets';
import { setAudioElements } from '../../actions';
import { createAudio } from '../../utils/audio';
import { Howler } from 'howler';

const { unmutedButton, mutedButton } = audioAssets;

function Audio() {
  const dispatch = useDispatch();
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    dispatch(setAudioElements(audioSources.map((src) => createAudio(src))));
  }, []);

  useEffect(() => {
    Howler.mute(isMuted);
  }, [isMuted]);

  const handleToggleClick = () => {
    setIsMuted((state) => !state);
  };

  return (
    <div className="Audio" onClick={handleToggleClick}>
      <img
        src={isMuted ? mutedButton : unmutedButton}
        className="Music__button-image"
        alt=""
      />
    </div>
  );
}

export default Audio;
