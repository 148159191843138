export const convertTextToImage = ({
  text,
  fontFace,
  fontSize,
  height,
  setImage,
  resultIndex,
}) => {
  const getPixelRatio = () => {
    const ctx = document.createElement('canvas').getContext('2d');
    const dpr = window.devicePixelRatio || 1;
    const bsr =
      ctx.webkitBackingStorePixelRatio ||
      ctx.mozBackingStorePixelRatio ||
      ctx.msBackingStorePixelRatio ||
      ctx.oBackingStorePixelRatio ||
      ctx.backingStorePixelRatio ||
      1;
    return dpr / bsr;
  };

  const createHiDPICanvas = (w, h) => {
    const ratio = getPixelRatio();
    const canvas = document.createElement('canvas');
    canvas.width = w * ratio;
    canvas.height = h * ratio;
    canvas.style.width = w + 'px';
    canvas.style.height = h + 'px';
    canvas.getContext('2d').setTransform(ratio, 0, 0, ratio, 0, 0);
    return canvas;
  };

  const canvas = createHiDPICanvas(window.innerWidth, height);
  const color = getComputedStyle(window.root).getPropertyValue(
    `--result-text-color-primary-${resultIndex + 1}`
  );

  const tCtx = canvas.getContext('2d');
  tCtx.font = fontFace;
  tCtx.fillStyle = color;
  tCtx.textAlign = 'center';
  tCtx.fillText(text, window.innerWidth / 2, fontSize);
  const src = tCtx.canvas.toDataURL();
  setImage(src);
};
