import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { animated, useSprings } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { clamp } from '../../utils/helpers';
import './Cards.css';

function Cards({ cards, resultIndex }) {
  const index = useRef(0);

  const { width: vw, height: vh } = useSelector(
    (state) => state.viewportReducer
  );

  const cardWidth = vh < 465 ? 225 : 273;
  let windowWidth = vw;
  let edgeWidth = (windowWidth - cardWidth) / 2;
  const cardEdgeWidthPercent = 0.375;

  const [cardSprings, setCardSprings] = useSprings(
    resultIndex === 16 ? 5 : 6,
    (i) => {
      return {
        x:
          (i - index.current) * cardWidth +
          edgeWidth +
          (i - index.current) * edgeWidth * cardEdgeWidthPercent,
      };
    }
  );

  const bind = useDrag(
    ({ down, direction: [xDir], vxvy: [vx], cancel, movement: [xMove] }) => {
      if (down && (Math.abs(xMove) > vw / 3 || Math.abs(vx) > 1)) {
        cancel(
          (index.current = clamp(
            index.current + (xDir > 0 ? -1 : 1),
            0,
            resultIndex === 16 ? 4 : 5
          ))
        );
      }

      setCardSprings((i) => ({
        x:
          (down ? xMove : 0) +
          (i - index.current) * cardWidth +
          edgeWidth +
          (i - index.current) * edgeWidth * cardEdgeWidthPercent,
      }));
    }
  );

  return (
    <div className="Cards" {...bind()}>
      {cardSprings.map(({ x }, i) => (
        <animated.div
          className="Cards__card"
          key={i}
          style={{ transform: x.interpolate((x) => `translate3d(${x}px,0,0)`) }}
        >
          <div
            className="Cards__card-top"
            style={{
              backgroundImage: `url(${
                resultIndex === 16 ? cards[i + 1] : cards[i]
              })`,
            }}
          />
          <div className="Cards__card-bottom">
            <FormattedMessage
              id={`forest_introduction_card_${
                i + (resultIndex === 16 ? 2 : 1)
              }`}
            />
          </div>
        </animated.div>
      ))}
    </div>
  );
}

export default Cards;
