import * as firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCcyuBfLzOw5ohxFBAe-DrI2VDJAmOtevw',
  authDomain: 'forest-mind-test-h5.firebaseapp.com',
  databaseURL: 'https://forest-mind-test-h5.firebaseio.com',
  projectId: 'forest-mind-test-h5',
  storageBucket: 'forest-mind-test-h5.appspot.com',
  messagingSenderId: '482118227198',
  appId: '1:482118227198:web:55c7218f3259082d7a01af',
  measurementId: 'G-TF98Q28SYV',
};

firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();
