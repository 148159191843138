import {
  UPDATE_PAGE,
  UPDATE_QUESTION,
  UPDATE_RENDER_STATE,
} from '../actions/types';

const initialState = {
  page: 0,
  question: 0,
  resultTrans: 0,
  isSpecialEnd: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RENDER_STATE:
      return { ...state, ...action.payload };

    case UPDATE_PAGE:
      return { ...state, page: action.payload };

    case UPDATE_QUESTION:
      return { ...state, question: action.payload };

    default:
      return state;
  }
};
