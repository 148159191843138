import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useSpring, useSprings, useTransition } from 'react-spring';
import { updateRenderState, updateScore } from '../../actions';
import {
  initInvisible,
  initSeed,
  initTransVisible,
  initVisible,
  seedsFadeOut,
  toInvisible,
  toVisible,
} from '../../animations';
import { page1_Assets } from '../../assets';
import { language } from '../../locale';
import score from '../../score';
import './Page1.css';

const { background, pot, mud, seeds, seedShadows, cloud } = page1_Assets;

const Page1 = () => {
  const dispatch = useDispatch();
  const { page } = useSelector((state) => state.renderStateReducer);

  const { width: vw, height: vh } = useSelector(
    (state) => state.viewportReducer
  );

  const [pageSpring, setPageSpring] = useSpring(initInvisible);

  const [showIntro, setShowIntro] = useState(true);
  const [introTextSpring, setIntroTextSpring] = useSpring(initInvisible);
  const [recommendSpring, setRecommendSpring] = useSpring(initInvisible);
  const [clickNoticeSpring, setClickNoticeSpring] = useSpring(initInvisible);

  const [questionSpring, setQuestionSpring] = useSpring(initInvisible);
  const [optionGroupSpring, setOptionGroupSpring] = useSpring(initInvisible);
  const [optionTextSpring, setOptionTextSpring] = useSpring(initVisible);

  const [optionSprings, setOptionSprings] = useSprings(4, (index) =>
    initSeed(index, vw, vh)
  );

  const canShowIntro = useRef(false);

  const canClickOption = useRef(false);

  const handlePageClick = () => {
    if (!canShowIntro.current) {
      return;
    }

    canShowIntro.current = false;
    setShowIntro(false);
  };

  const intro = () => {
    setIntroTextSpring({
      ...toVisible,
      onRest: () => {
        setRecommendSpring({
          delay: 1000,
          ...toVisible,
          onRest: () => {
            setClickNoticeSpring({
              onStart: () => {
                canShowIntro.current = true;
              },
              to: async (next) => {
                while (1) {
                  await next({ opacity: 1 });
                  await next({ opacity: 0.3 });
                  await next({ opacity: 1 });
                }
              },
            });
          },
        });
      },
    });
  };

  const afterIntro = () => {
    setQuestionSpring({
      ...toVisible,
      config: { duration: 1000 },
      onRest: () => {
        setOptionGroupSpring({
          ...toVisible,
          onRest: () => {
            canClickOption.current = true;
          },
        });
      },
    });
  };

  const introTransition = useTransition(showIntro, null, {
    ...initTransVisible(),
    onDestroyed: afterIntro,
  });

  const toNextPage = () => {
    setTimeout(() => {
      dispatch(updateRenderState({ page: 3 }));
    }, 1000);
  };

  const handleOptionClick = (index) => () => {
    if (!canClickOption.current) return;
    canClickOption.current = false;
    dispatch(updateRenderState({ page: 2 }));
    dispatch(updateScore(score.q1[index]));
    setQuestionSpring({ ...toInvisible, onRest: () => {} });
    setOptionTextSpring(toInvisible);
    setOptionSprings(seedsFadeOut(index, toNextPage, vw, vh));
  };

  useEffect(() => {
    setOptionSprings((index) => initSeed(index, vw, vh));
  }, [vw, vh]);

  useEffect(() => {
    page === 1 && setPageSpring({ ...toVisible, onRest: intro });
  }, [page]);

  return (
    <animated.div
      className="Page1"
      onClick={handlePageClick}
      style={{ ...pageSpring, backgroundImage: `url(${background})` }}
    >
      {introTransition.map(
        ({ item, key, props }) =>
          item && (
            <animated.div className="Page1__intro" style={props} key={key}>
              <animated.span
                className={`Page1__intro-text ${language}`}
                style={introTextSpring}
              >
                <FormattedMessage
                  id="introduction_section"
                  values={{ br: <br /> }}
                />
              </animated.span>
              <animated.span
                className={`Page1__recommendation ${language}`}
                style={recommendSpring}
              >
                <FormattedMessage id="introduction_sentence_1" />
              </animated.span>
              <animated.div
                className={`Page1__click-notice ${language}`}
                style={clickNoticeSpring}
              >
                <FormattedMessage id="introduction_sentence_2" />
              </animated.div>
            </animated.div>
          )
      )}
      <>
        <animated.div className="Page1__cloud" style={questionSpring}>
          <img src={cloud} alt="" />
        </animated.div>
        <animated.span
          className={`Page1__question-text ${language}`}
          style={questionSpring}
        >
          <FormattedMessage id="question_1" />
        </animated.span>
        <animated.div className="Page1__option-group" style={optionGroupSpring}>
          {optionSprings.map((spring, index) => (
            <animated.div
              className={`Page1__option ${language}`}
              key={index}
              style={spring}
            >
              <animated.span
                className={`Page1__option-text ${language}`}
                style={optionTextSpring}
              >
                <FormattedMessage id={`question_1_option_${index + 1}`} />
              </animated.span>
              <animated.div
                className="Page1__option-image"
                onClick={handleOptionClick(index)}
              >
                <img src={seeds[index]} alt="" />
              </animated.div>
              <animated.div
                className="Page1__seed-shadow"
                style={questionSpring}
              >
                <img src={seedShadows[index]} alt="" />
              </animated.div>
            </animated.div>
          ))}
        </animated.div>
      </>

      <animated.div className="Page1__pot-image-container">
        <img src={pot} alt="" />
      </animated.div>
      <div className="Page1__mud">
        <img src={mud} alt="" />
      </div>
    </animated.div>
  );
};

export default Page1;
