import tc from './locale/zh_TW.json';
import sc from './locale/zh_Hans_CN.json';
import en from './locale/en.json';
import ko from './locale/ko.json';
import ja from './locale/ja.json';
import es from './locale/es.json';

const messsages = { tc, sc, en, ko, ja, es };

const checkLocaleParam = () => {
  const queryString = window.location.search;
  if (queryString.length === 0) return false;
  const params = new URLSearchParams(queryString);
  const locale = params.get('locale');
  if (!locale) return false;
  if (!!locale.match(/zh-tw/)) return 'zh-TW';
  else if (!!locale.match(/zh-cn/)) return 'zh-CN';
  else if (!!locale.match(/en/)) return 'en';
  else if (!!locale.match(/ko/)) return 'ko';
  else if (!!locale.match(/ja/)) return 'ja';
  else if (!!locale.match(/es/)) return 'es';
  else return false;
};

export const locale = checkLocaleParam() || navigator.language;

const getLanguage = () => {
  if (!!locale.match(/zh-TW|zh-HK|zh-Hant|zh-Hant-TW|zh_TW/i)) return 'tc';
  else if (!!locale.match(/zh|zh-CN|zh-Hans|zh-Hans-CN|zh_CN/i)) return 'sc';
  else if (!!locale.match(/ko/i)) return 'ko';
  else if (!!locale.match(/en/i)) return 'en';
  else if (!!locale.match(/ja/i)) return 'ja';
  else if (!!locale.match(/es/i)) return 'es';
  else return 'en';
};

export const language = getLanguage();

export const message = messsages[language];
