import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useSpring, useSprings, useTransition } from 'react-spring';
import { updateRenderState, updateScore } from '../../actions';
import { FormattedMessage } from 'react-intl';
import { loadLottie } from '../../utils/lottie';
import * as easings from 'd3-ease';
import score from '../../score';
import {
  initInvisible,
  initVisible,
  toInvisible,
  toVisible,
  initTransVisible,
  initTransInvisible,
  booksMove,
  initBooksMove,
  initBlur,
  eyeClose,
  blur,
} from '../../animations';
import './Page3.css';
import {
  phoneLottie,
  memoLottie,
  notebookLottie,
  bookLotties,
} from '../../assets';
import { page3_Assets } from '../../assets';
import { ga } from '../../utils/analytics';
import { language } from '../../locale';

const { background, windowLight } = page3_Assets;

const Page3 = () => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const canClick = useRef(false);
  const { question, resultTrans } = useSelector(
    (state) => state.renderStateReducer
  );
  const { audioElements, currentAudioIndex } = useSelector(
    (state) => state.audioReducer
  );
  const [bookIndex, setBookIndex] = useState(null);
  const [bookSprings, setBookSprings] = useSprings(4, initBooksMove());
  const book_1 = useRef(null);
  const book_2 = useRef(null);
  const book_3 = useRef(null);
  const book_4 = useRef(null);
  const bookRefs = [book_1, book_2, book_3, book_4];
  const [bookAnimInstances, setBookAnimInstances] = useState([]);
  const phoneRef = useRef(null);
  const [phoneAnim, setPhoneAnim] = useState(null);
  const memoRef = useRef(null);
  const [memoAnim, setMemoAnim] = useState(null);
  const notebookRef = useRef(null);
  const [notebookAnim, setNotebookAnim] = useState(null);
  const noteMethodIndex = useRef(null);
  const [showNote, setShowNote] = useState(false);
  const [canClickBook, setCanClickBook] = useState(true);
  const [q3_Description, setQ3_Description] = useSpring(initInvisible);
  const [q3_TextSpring, setQ3_TextSpring] = useSpring(initInvisible);
  const [q3_Spring, setQ3_Spring] = useSpring(initVisible);
  const [
    tapNoticeSpring,
    setTapNoticeSpring,
    cancelTapNoticeSpring,
  ] = useSpring(initInvisible);
  const [windowLightSpring, setWindowLightSpring] = useSpring(() => ({
    transform: `matrix3d(1.1925465838509317,-0.23222748815165878,0,0.00045627152571310826,0,1.1925465838509317,0,0, 0,0,1,0,0,98,0,1)`,
    opacity: 1,
  }));
  const [envLightSpring, setEnvLightSpring] = useSpring(() => ({
    opacity: 0,
    backgroundColor: '#9a8b68',
  }));

  const lightFromAfternoonToNight = () => {
    const duration = noteMethodIndex.current === 0 ? 14000 : 9000;
    setWindowLightSpring({
      transform: `matrix3d(
                        1.1923979910249933, 0.6587677725118484, 0, 0.0004559194100118325,
                        0.012541234509197896, 1.2019673690154238, 0, 0.000029718565187672738,
                        0, 0, 1, 0,
                        0, -278, 0, 1)`,
      opacity: 0,
      config: { duration, easing: (t) => easings.easeSinInOut(t) },
    });
    setEnvLightSpring({
      opacity: 0.38,
      backgroundColor: '#020655',
      config: { duration: 6000 },
    });
  };

  const lightFromMorningToAfternoon = () => {
    setWindowLightSpring({
      to: [
        {
          transform: `matrix3d(0.7737865333838095,0.03317535545023697,0,0.0004073241677408843,-0.020318744282343877,1.1563869228176609,0,-0.0000481486831335163,0,0,1,0,168,-14,0,1)`,
          opacity: 1,
          config: { duration: 4500 },
        },
        {
          config: { duration: 4500 },
          opacity: 1,
          transform: `matrix3d(1.010728736542666, 0.4075829383886256, 0,0.0004072658229880567,0.00744635798983625, 1.177547995482778, 0, 0.000017645398080180688,0, 0, 1, 0, 68, -172, 0,1)`,
        },
      ],
    });
    setEnvLightSpring({
      opacity: 0.4,
      backgroundColor: '#6f330e',
      config: { duration: 9000 },
    });
  };

  const isReadBookCompleted = useRef(false);
  const isPhonePicked = useRef(false);

  const clickAfterBookRead = () => {
    if (!isReadBookCompleted.current) return;
    cancelTapNoticeSpring();
    canClick.current = false;
    dispatch(updateRenderState({ resultTrans: 0 }));
  };

  const openBookAndRead = () => {
    setTimeout(lightFromMorningToAfternoon, 1500);
    bookAnimInstances[bookIndex].playSegments(
      [
        [0, 90],
        [120, 210],
        [120, 210],
        [120, 210],
      ],
      true
    );
    bookAnimInstances[bookIndex].onComplete = () => {
      bookAnimInstances[bookIndex].onComplete = null;
      canClick.current = true;
      setTapNoticeSpring({
        to: async (next) => {
          while (1) {
            await next({ opacity: 1 });
            await next({ opacity: 0.3 });
            await next({ opacity: 1 });
          }
        },
      });
      isReadBookCompleted.current = true;
    };
  };

  const playMessageSound = () => {
    audioElements[1].pause();
    audioElements[2].pause();
    audioElements[3].pause();
    audioElements[4].pause();
    audioElements[5].loop(false);
    const msgSoundFrames = [20, 114, 234, 384, 532];
    const phoneLightFrame = 26;
    const phoneLightLowFrame = 660;
    let nextSoundIndex = 0;
    let phoneLightGate = true;
    let phoneLightLowGate = true;
    phoneAnim.onEnterFrame = (e) => {
      if (isPhonePicked.current) return;
      if (msgSoundFrames[nextSoundIndex] - e.currentTime < 0) {
        nextSoundIndex += 1;
        audioElements[5].play();
      }
      if (phoneLightLowFrame - e.currentTime < 0) {
        if (!phoneLightLowGate) return;
        phoneLightLowGate = false;
        setPhoneLightLow(true);
      }
      if (phoneLightFrame - e.currentTime < 0) {
        if (!phoneLightGate) return;
        phoneLightGate = false;
        setPhoneLight(true);
      }
    };
  };

  const phoneRingAnim = () => {
    setPhoneRing(true);
    setRenderQ7_Situation(true);
    setQ7_Situation1_Spring({
      delay: 1000,
      ...toVisible,
      onRest: () => {
        setQ7_Situation2_Spring({
          delay: 500,
          ...toVisible,
          onRest: null,
        });
      },
    });
    playMessageSound();
    phoneAnim.play();
    phoneAnim.onComplete = () => {
      setPhoneRing(false);
      setPhoneLight(false);
      closeBook();
      setRenderQ7_Situation(false);
    };

    setTimeout(switchNoteFadeOut, 1000);
  };

  const onNoteAnimComplete = () => {
    phoneRingAnim();
    bookAnimInstances[bookIndex].onComplete = null;
  };

  const rememberInBrain = () => {
    bookAnimInstances[bookIndex].playSegments(
      [
        [120, 210],
        [120, 210],
        [120, 210],
      ],
      true
    );
    bookAnimInstances[bookIndex].onComplete = onNoteAnimComplete;
  };

  const noNoteFadeOutAndRead = () => {
    bookAnimInstances[bookIndex].playSegments(
      [...Array(9)].map(() => [120, 210]),
      true
    );
  };

  const highlightFadeOutAndRead = () => {
    bookAnimInstances[bookIndex].playSegments([502, 542], true);
    bookAnimInstances[bookIndex].onComplete = () => {
      bookAnimInstances[bookIndex].onComplete = null;
      bookAnimInstances[bookIndex].playSegments(
        [...Array(8)].map(() => [120, 210]),
        true
      );
    };
  };

  const postNoteFadeOutAndRead = () => {
    bookAnimInstances[bookIndex].playSegments([794, 826], true);
    bookAnimInstances[bookIndex].onComplete = () => {
      bookAnimInstances[bookIndex].onComplete = null;
      bookAnimInstances[bookIndex].playSegments(
        [...Array(9)].map(() => [120, 210]),
        true
      );
    };
  };

  const writeOnBook = () => {
    bookAnimInstances[bookIndex].playSegments([240, 510], true);
    bookAnimInstances[bookIndex].onComplete = onNoteAnimComplete;
  };

  const pasteNoteOnBook = () => {
    bookAnimInstances[bookIndex].playSegments([721, 794], true);
    bookAnimInstances[bookIndex].onComplete = onNoteAnimComplete;
  };

  const closeBook = () => {
    bookAnimInstances[bookIndex].playSegments([1021, 1160], true);
    bookAnimInstances[bookIndex].onComplete = () => {
      toEasterEgg();
    };
  };

  const switchNoteFadeOut = () => {
    switch (noteMethodIndex.current) {
      case 0:
        noNoteFadeOutAndRead();
        return;
      case 1:
        postNoteFadeOutAndRead();
        break;
      case 2:
        noNoteFadeOutAndRead();
        return;
      case 3:
        highlightFadeOutAndRead();
        return;
      default:
        return;
    }
  };

  const writeMemo = () => {
    memoAnim.playSegments([0, 180], true);
    memoAnim.onComplete = () => {
      pasteNoteOnBook();
      memoAnim.onComplete = null;
    };
  };

  const openWriteNotebook = () => {
    notebookAnim.playSegments([0, 390], true);
    notebookAnim.onComplete = () => {
      phoneRingAnim();
    };
  };

  const handleBookClick = (index) => () => {
    if (!canClick) return;
    if (!canClickBook) return;
    canClick.current = false;
    setCanClickBook(false);
    dispatch(updateScore(score.q3[index]));
    setBookIndex(index);
    setQ3_Spring(toInvisible);
    setBookSprings(
      booksMove(
        index,
        () => {
          const toBeDestroyed = [...bookAnimInstances];
          toBeDestroyed.splice(index, 1);
          toBeDestroyed.forEach((item) => item.destroy());
        },
        () => {
          dispatch(updateRenderState({ question: 4 }));
        }
      )
    );
  };

  const [q4_TextSpring, setQ4_TextSpring] = useSpring(initInvisible);
  const [q4_OptionsSpring, setQ4_OptionsSpring] = useSpring(initInvisible);
  const [q4_OptionSprings, setQ4_OptionSprings] = useSprings(4, initVisible);

  const q4_trans = useTransition(question, null, {
    ...initTransVisible(800),
    onDestroyed: (item) => {
      item === 4 &&
        setTimeout(() => {
          dispatch(updateRenderState({ resultTrans: 4 }));
        }, 500);
    },
  });
  const q4_ResultTrans = useTransition(resultTrans, null, {
    ...initTransInvisible(800),
    onDestroyed: (item) => {
      item === 4 &&
        setTimeout(() => {
          dizzy();
        }, 2000);
    },
  });

  const handleQ4_OptionClick = (index) => () => {
    if (!canClick.current) return;
    canClick.current = false;
    dispatch(updateScore(score.q4[index]));
    setQ4_OptionSprings((springIndex) => {
      return springIndex === index
        ? {
            delay: 1000,
            ...toVisible,
            onRest: () => {
              openBookAndRead();
              dispatch(
                updateRenderState({
                  question: 0,
                })
              );
            },
          }
        : {
            ...toInvisible,
          };
    });
  };

  const [blurSpring, setBlur] = useSpring(initBlur);
  const [renderEye, setRenderEye] = useState(true);
  const [eyeSpring, setEyeSpring] = useSpring(() => ({
    from: { maxHeight: '0%' },
  }));

  const [q5_DescriptionSpring, setQ5_DescriptionSpring] = useSpring(
    initInvisible
  );
  const [q5_TextSpring, setQ5_TextSpring] = useSpring(initInvisible);
  const [q5_OptionsSpring, setQ5_OptionsSpring] = useSpring(initInvisible);
  const [q5_OptionSprings, setQ5_OptionSprings] = useSprings(4, initVisible);
  const q5_Trans = useTransition(question, null, initTransVisible());

  const [renderQ5_Overlay, setRenderQ5_Overlay] = useState(false);
  const [q5_OverlaySpring, setQ5_OverlaySpring] = useSpring(initInvisible);
  const [q5_SituationSpring, setQ5_SituationSpring] = useSpring(initInvisible);

  const [q6_DescriptionSpring, setQ6_DescriptionSpring] = useSpring(
    initInvisible
  );
  const [q6_TextSpring, setQ6_TextSpring] = useSpring(initInvisible);
  const [q6_OptionsSpring, setQ6_OptionsSpring] = useSpring(initInvisible);
  const [q6_OptionSprings, setQ6_OptionSprings] = useSprings(4, initVisible);
  const q6_Trans = useTransition(question, null, initTransVisible());

  const [renderQ7_Situation, setRenderQ7_Situation] = useState(false);
  const [q7_Situation1_Spring, setQ7_Situation1_Spring] = useSpring(
    initInvisible
  );
  const [q7_Situation2_Spring, setQ7_Situation2_Spring] = useSpring(
    initInvisible
  );

  const [phoneRing, setPhoneRing] = useState(false);
  const [phoneLight, setPhoneLight] = useState(false);
  const [phoneLightLow, setPhoneLightLow] = useState(false);

  const toEasterEgg = () => {
    ga.enterEasterEgg();
    dispatch(updateRenderState({ page: 15, isSpecialEnd: true }));
  };

  const handleQ6_OptionClick = (index) => () => {
    noteMethodIndex.current = index;
    const switchAnim = () => {
      switch (index) {
        case 0:
          openWriteNotebook();
          break;
        case 1:
          writeMemo();
          break;
        case 2:
          rememberInBrain();
          break;
        case 3:
          writeOnBook();
          break;
        default:
          break;
      }
    };

    dispatch(updateScore(score.q6[index]));
    setQ6_OptionSprings((springIndex) => {
      return index === springIndex
        ? {
            to: { opacity: 1 },
            delay: 2000,
            onRest: () => {
              dispatch(updateRenderState({ question: 0, page: 8 }));
              setTimeout(() => {
                switchAnim();
                lightFromAfternoonToNight();
              }, 1000);
            },
          }
        : {
            to: { opacity: 0 },
          };
    });
  };

  function dizzy() {
    setEyeSpring({
      ...eyeClose,
      onRest: () => {
        setRenderEye(false);
        setTimeout(() => {
          dispatch(updateRenderState({ question: 5 }));
        }, 1000);
      },
    });
    setBlur(blur(setBlur));
  }

  const afterQ5_OverlayShown = () => {
    setShowNote(true);
    dispatch(updateRenderState({ page: 14 }));
    setQ5_SituationSpring({
      delay: 1000,
      to: { opacity: 1 },
      onRest: () => {
        setQ5_OverlaySpring({
          delay: 3000,
          ...toInvisible,
          config: { duration: 1000 },
          onRest: () => {
            setRenderQ5_Overlay(false);
            setTimeout(() => {
              dispatch(updateRenderState({ question: 6 }));
            }, 1000);
          },
        });
      },
    });
  };

  const afterQ5 = () => {
    dispatch(updateRenderState({ question: 0 }));
    setRenderQ5_Overlay(true);
    setQ5_OverlaySpring({
      ...toVisible,
      onRest: afterQ5_OverlayShown,
    });
  };

  const handleQ5_OptionClick = (index) => () => {
    if (!canClick.current) return;
    canClick.current = false;
    dispatch(updateScore(score.q5[index]));
    setQ5_OptionSprings((springIndex) => {
      return index === springIndex
        ? {
            to: { opacity: 1 },
            delay: 2000,
            onRest: () => {
              afterQ5();
            },
          }
        : {
            to: { opacity: 0 },
          };
    });
  };

  const handlePhoneClick = () => {
    if (!phoneRing) return;
    if (!canClick) return;
    canClick.current = false;
    isPhonePicked.current = true;
    phoneAnim.onComplete = null;
    currentAudioIndex && audioElements[currentAudioIndex].play();
    dispatch(updateRenderState({ page: 9 }));
  };

  useEffect(() => {
    bookAnimInstances.length === 0 &&
      setBookAnimInstances((state) => [
        ...state,
        ...bookLotties.map((lottie, index) =>
          loadLottie(bookRefs[index].current, lottie, false, false)
        ),
      ]);
    setPhoneAnim(loadLottie(phoneRef.current, phoneLottie, false, false));
    setMemoAnim(loadLottie(memoRef.current, memoLottie, false, false));
    setNotebookAnim(
      loadLottie(notebookRef.current, notebookLottie, false, false)
    );
  }, []);

  useEffect(() => {
    question === 3 &&
      setQ3_Description({
        ...toVisible,
        onRest: () => {
          setQ3_TextSpring({
            ...toVisible,
            onRest: () => {
              canClick.current = true;
            },
          });
        },
      });
    question === 4 &&
      setQ4_TextSpring({
        ...toVisible,
        onRest: () => {
          setQ4_OptionsSpring({
            ...toVisible,
            onRest: () => {
              canClick.current = true;
            },
          });
        },
      });
    question === 5 &&
      setQ5_DescriptionSpring({
        ...toVisible,
        onRest: () => {
          setQ5_TextSpring({
            ...toVisible,
            onRest: () => {
              setQ5_OptionsSpring({
                ...toVisible,
                onRest: () => {
                  canClick.current = true;
                },
              });
            },
          });
        },
      });
    question === 6 &&
      setQ6_DescriptionSpring({
        ...toVisible,
        onRest: () => {
          setQ6_TextSpring({
            ...toVisible,
            onRest: () => {
              setQ6_OptionsSpring({
                ...toVisible,
                onRest: () => {
                  canClick.current = true;
                },
              });
            },
          });
        },
      });
  }, [question]);

  return (
    <animated.div
      className="Page3"
      ref={pageRef}
      style={{
        ...blurSpring,
        backgroundImage: `url(${background})`,
      }}
    >
      <div
        className={`Page3__phone ${phoneLight ? 'Page3__phone--light' : ''}`}
        ref={phoneRef}
      >
        <div
          className={`Page3__phone-click-area ${
            phoneLight
              ? phoneLightLow
                ? ''
                : 'Page3__phone-click-area--light'
              : ''
          }`}
          onClick={handlePhoneClick}
        />
      </div>
      <div
        className={`Page3__memo ${showNote ? '' : 'Page3__note--hidden'}`}
        ref={memoRef}
      />
      <div
        className={`Page3__notebook ${showNote ? '' : 'Page3__note--hidden'}`}
        ref={notebookRef}
      />

      {renderQ5_Overlay && (
        <animated.div className="question-5__overlay" style={q5_OverlaySpring}>
          <animated.div
            className="question-5__overlay-situation"
            style={q5_SituationSpring}
          >
            <FormattedMessage id="situation_break" />
          </animated.div>
        </animated.div>
      )}

      {renderEye && (
        <div className="Page3__dizzy">
          <animated.div className="Page3__dizzy-top" style={eyeSpring} />
          <animated.div className="Page3__dizzy-bottom" style={eyeSpring} />
        </div>
      )}

      {question === 3 && (
        <animated.div className="question-3" style={q3_Spring}>
          <animated.span className="Page3__description" style={q3_Description}>
            <FormattedMessage id="question_3_description" />
          </animated.span>

          <animated.span
            className={`Page3__question-text ${language}`}
            style={q3_TextSpring}
          >
            <FormattedMessage id="question_3" />
          </animated.span>
        </animated.div>
      )}

      {q4_trans.map(
        ({ item, props, key }) =>
          item === 4 && (
            <animated.div className="question-4" style={props} key={key}>
              <animated.span
                className={`question-4-text ${language}`}
                style={q4_TextSpring}
              >
                <FormattedMessage id="question_4" values={{ br: <br /> }} />
              </animated.span>
              <animated.div className="q4__options" style={q4_OptionsSpring}>
                {q4_OptionSprings.map((props, index) => (
                  <animated.div
                    className="option-normal"
                    style={props}
                    key={index}
                    onClick={handleQ4_OptionClick(index)}
                  >
                    <FormattedMessage
                      id={`question_4_option_${index + 1}`}
                      values={{ br: <br /> }}
                    />
                  </animated.div>
                ))}
              </animated.div>
            </animated.div>
          )
      )}

      {q4_ResultTrans.map(
        ({ item, props, key }) =>
          item === 4 && (
            <animated.div
              className="question-4__result"
              style={props}
              key={key}
              onClick={clickAfterBookRead}
            >
              <div className="question-4__result-text-area">
                <FormattedMessage
                  id={`book_${bookIndex + 1}_description`}
                  values={{ br: <br /> }}
                />
                <animated.div
                  className={`Page3__tap-notice ${language}`}
                  style={tapNoticeSpring}
                >
                  <FormattedMessage id="tap_to_continue" />
                </animated.div>
              </div>
            </animated.div>
          )
      )}

      {q5_Trans.map(
        ({ item, props, key }) =>
          item === 5 && (
            <animated.div className="question-5" style={props} key={key}>
              <animated.div
                className={`question-5__description ${language}`}
                style={q5_DescriptionSpring}
              >
                <FormattedMessage id="question_5_description" />
              </animated.div>
              <animated.div className="question-5__text" style={q5_TextSpring}>
                <FormattedMessage id="question_5" />
              </animated.div>
              <animated.div
                className="question-5__options"
                style={q5_OptionsSpring}
              >
                {q5_OptionSprings.map((props, index) => (
                  <animated.div
                    className="question-5__option"
                    style={props}
                    key={index}
                    onClick={handleQ5_OptionClick(index)}
                  >
                    <FormattedMessage id={`question_5_option_${index + 1}`} />
                  </animated.div>
                ))}
              </animated.div>
            </animated.div>
          )
      )}

      {q6_Trans.map(
        ({ item, props, key }) =>
          item === 6 && (
            <animated.div className="question-6" style={props} key={key}>
              <animated.div
                className="question-6__description"
                style={q6_DescriptionSpring}
              >
                <FormattedMessage id="question_6_description" />
              </animated.div>
              <animated.div className="question-6__text" style={q6_TextSpring}>
                <FormattedMessage id="question_6" values={{ br: <br /> }} />
              </animated.div>
              <animated.div
                className="question-6__options"
                style={q6_OptionsSpring}
              >
                {q6_OptionSprings.map((props, index) => (
                  <animated.div
                    className="question-6__option"
                    style={props}
                    key={index}
                    onClick={handleQ6_OptionClick(index)}
                  >
                    <FormattedMessage id={`question_6_option_${index + 1}`} />
                  </animated.div>
                ))}
              </animated.div>
            </animated.div>
          )
      )}

      {renderQ7_Situation && (
        <div className="q7_Situation">
          <animated.div
            className={`q7_Situation-text--1 ${language}`}
            style={q7_Situation1_Spring}
          >
            <FormattedMessage
              id="situation_message_1"
              values={{ br: <br /> }}
            />
          </animated.div>
          <animated.div
            className={`q7_Situation-text--2 ${language}`}
            style={q7_Situation2_Spring}
          >
            <FormattedMessage id="situation_message_2" />
          </animated.div>
        </div>
      )}

      {bookSprings.map((props, index) => (
        <animated.div
          style={props}
          key={index}
          ref={bookRefs[index]}
          className={`book_${index + 1}`}
        >
          <div
            className="Page3__book-click-area"
            onClick={handleBookClick(index)}
          />
        </animated.div>
      ))}
      <animated.div className="Page3__window-light" style={windowLightSpring}>
        <img src={windowLight} alt="" />
      </animated.div>
      <animated.div
        className="Page3__env-light"
        style={envLightSpring}
      ></animated.div>
    </animated.div>
  );
};

export default Page3;
