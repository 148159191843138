import React, { useEffect } from 'react';
import './Modal.css';
import { FormattedMessage } from 'react-intl';
import { animated, useSpring } from 'react-spring';
import { initInvisible, toVisible } from '../../animations';

import CloseIcon from './CloseIcon';
import FbIcon from './FbIcon';
import WeiboIcon from './WeiboIcon';
import IgIcon from './IgIcon';
import TwitterIcon from './TwitterIcon';
import ArrowIcon from './ArrowIcon';
import { language } from '../../locale';
import { ver } from '../../version';

function Modal({
  bookmateIndex,
  resultIndex,
  style,
  closeModal,
  flower,
  background,
}) {
  const [contentSpring, setContentSpring] = useSpring(initInvisible);

  useEffect(() => {
    setContentSpring({ delay: 300, ...toVisible });
    const color = getComputedStyle(window.root).getPropertyValue(
      `--result-text-color-primary-${bookmateIndex + 1}`
    );
    window.root.style.setProperty('--modal-icon-fill-color', color);
  }, []);

  const handleWeiboClick = () => {
    window.open(
      'https://s.weibo.com/weibo?q=%23Forest%E5%AE%8C%E7%BE%8E%E4%B9%A6%E4%BC%B4%23',
      '_blank'
    );
  };

  const handleFacebookClick = () => {
    window.open(
      'https://www.facebook.com/hashtag/%E6%88%91%E7%9A%84%E5%B0%88%E6%B3%A8%E9%A1%9E%E5%9E%8B',
      '_blank'
    );
  };

  const handleInstagramClick = () => {
    const open = (url) => window.open(url, '_blank');

    if (language === 'en') {
      open('https://www.instagram.com/explore/tags/foreststudybuddy');
    } else if (language === 'ko') {
      open(
        'https://www.instagram.com/explore/tags/Forest%EC%A7%91%EC%A4%91%EC%9C%A0%ED%98%95%EB%B6%84%EC%84%9D/'
      );
    } else if (language === 'tc') {
      open(
        'https://www.instagram.com/explore/tags/%E6%88%91%E7%9A%84%E5%B0%88%E6%B3%A8%E9%A1%9E%E5%9E%8B/'
      );
    } else if (language === 'ja') {
      open(
        'https://www.instagram.com/explore/tags/forest%E8%8A%B1%E8%A8%80%E8%91%89%E5%BF%83%E7%90%86%E3%83%86%E3%82%B9%E3%83%88/'
      );
    } else if (language === 'es') {
      open('https://www.instagram.com/explore/tags/foreststudybuddy');
    } else {
      open('https://www.instagram.com/explore/tags/foreststudybuddy');
    }
  };

  const handleTwitterClick = () => {
    const open = (url) => window.open(url, '_blank');

    switch (language) {
      case 'en':
        open('https://twitter.com/hashtag/foreststudybuddy');
        break;

      case 'ko':
        open(
          'https://twitter.com/hashtag/Forest%EC%A7%91%EC%A4%91%EC%9C%A0%ED%98%95%EB%B6%84%EC%84%9D?src=hash'
        );
        break;

      case 'ja':
        open(
          'https://twitter.com/hashtag/forest%E8%8A%B1%E8%A8%80%E8%91%89%E5%BF%83%E7%90%86%E3%83%86%E3%82%B9%E3%83%88'
        );
        break;

      case 'es':
        open('https://twitter.com/hashtag/foreststudybuddy');
        break;

      default:
        open('https://twitter.com/hashtag/foreststudybuddy');
        break;
    }
  };

  const switchHashTags = () => {
    if (ver === 'china' || language === 'sc') {
      return (
        <div className="Modal__icon-container" onClick={handleWeiboClick}>
          <WeiboIcon />
        </div>
      );
    } else {
      switch (language) {
        default:
          return;

        case 'en':
        case 'es':
        case 'ko':
        case 'ja':
          return (
            <>
              <div
                className={`Modal__icon-container ${language}`}
                onClick={handleTwitterClick}
              >
                <TwitterIcon />
              </div>
              <div
                className={`Modal__icon-container ${language}`}
                onClick={handleInstagramClick}
              >
                <IgIcon />
              </div>
            </>
          );

        case 'tc':
          return (
            <div
              className="Modal__icon-container"
              onClick={handleInstagramClick}
            >
              <IgIcon />
            </div>
          );
      }
    }
  };

  return (
    <animated.div
      className="Modal"
      style={{
        ...style,
        color: `var(--result-text-color-primary-${bookmateIndex + 1})`,
      }}
    >
      <animated.div
        className="Modal__content"
        style={{
          ...contentSpring,
          backgroundImage: `url(${background})`,
        }}
      >
        <div className="Modal__close" onClick={closeModal}>
          <CloseIcon />
        </div>
        <div className={`Modal__title bold ${language}`}>
          <FormattedMessage id="poster_pal_dialog_name" />
        </div>
        <div className="Modal__pal-name">
          <FormattedMessage id={`flower_meaning_${bookmateIndex + 1}_name`} />
        </div>
        <div
          className="Modal__pal-description"
          style={{
            color: `var(--result-text-color-secondary-${bookmateIndex + 1})`,
          }}
        >
          <FormattedMessage
            id={`flower_meaning_${resultIndex + 1}_pal_description`}
          />
        </div>
        <img className="Modal__pal-image" src={flower} alt="" />

        <div className="Modal__find-pal-section">
          <div className="Modal__find-pal-title">
            <div
              className="Modal__find-pal-break"
              style={{
                backgroundColor: `var(--result-text-color-primary-${
                  bookmateIndex + 1
                })`,
              }}
            />
            <span className="Modal__find-pal-title-text bold">
              <FormattedMessage id="poster_pal_dialog_subtitle_new" />
            </span>
            <div
              className="Modal__find-pal-break"
              style={{
                backgroundColor: `var(--result-text-color-primary-${
                  bookmateIndex + 1
                })`,
              }}
            />
          </div>
          <div
            className="Modal__find-pal-description"
            style={{
              color: `var(--result-text-color-secondary-${bookmateIndex + 1})`,
              ...(language === 'en' && { lineHeight: 1.3 }),
            }}
          >
            <FormattedMessage
              id="poster_pal_dialog_caption_new"
              values={{
                bold: (
                  <span className="hashtag-bold">
                    <FormattedMessage id="poster_generate_pal_description_hashtag" />
                  </span>
                ),
              }}
            />
          </div>
          <div className="Modal__to-hash-tag">
            {language !== 'en' && (
              <>
                <div className="Modal__click-text">
                  <FormattedMessage id="poster_pal_dialog_instruction_new" />
                </div>
                <div className="Modal__arrow-icon">
                  <ArrowIcon />
                </div>
              </>
            )}
            {switchHashTags()}
          </div>
        </div>
      </animated.div>
    </animated.div>
  );
}

export default Modal;
