import flower from 'assets/images/loader/flower.png';
import pot from 'assets/images/loader/pot.png';
import React from 'react';
import { animated } from 'react-spring';
import './Loader.css';

function Loader({ style, progress }) {
  return (
    <animated.div style={style} className="Loader">
      <div className="Loader__plant">
        <div className="Loader__flower">
          <img src={flower} alt="" />
        </div>
        <div className="Loader__pot">
          <img src={pot} alt="" />
        </div>
      </div>
      <div className="Loader__progress">{progress}%</div>
    </animated.div>
  );
}

export default Loader;
