import * as easings from 'd3-ease';
import { Howler } from 'howler';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSpring } from 'react-spring';
import { updateRenderState } from '../../actions';
import { getPhoneSpringProps, getScrollSpringProps } from '../../animations';
import waitFor from '../../utils/waitFor';

const useRenderState = () => {
  const dispatch = useDispatch();
  const { height: vh } = useSelector((state) => state.viewportReducer);
  const { page } = useSelector((state) => state.renderStateReducer);
  const { audioElements } = useSelector((state) => state.audioReducer);
  const [isBgDark, setIsBgDark] = useState(false);

  const [phoneSpring, setPhoneSpring] = useSpring(getPhoneSpringProps);
  const [scrollSpring, setScroll] = useSpring(getScrollSpringProps);

  const [renderState, setRenderState] = useState({
    renderAudioToggle: false,
    renderStartPage: false,
    renderPage1: false,
    renderPage2: false,
    renderPage3: false,
    renderPhone: false,
    renderWindowPage: false,
    renderNamePage: false,
    renderEasterEgg: false,
    renderResultPage: false,
  });

  const preventScrollingOnPhoneRotate = async () => {
    await waitFor(100);
    window.scrollTo(0, 3500);
  };

  const raisePhone = () => {
    setPhoneSpring({
      to: { transform: `translate3d(0px, 0%, 0) rotate(0deg)` },
      onRest: () => {
        dispatch(updateRenderState({ page: 10 }));
      },
    });
  };

  const dropPhone = async () => {
    setPhoneSpring({
      to: { transform: `translate3d(0px, 120%, 0) rotate(-30deg)` },
      onRest: undefined,
    });

    await waitFor(1200);
    dispatch(updateRenderState({ page: 12 }));
  };

  useEffect(() => {
    switch (page) {
      default:
        break;

      case 0:
        setRenderState((state) => ({
          ...state,
          renderStartPage: true,
          renderAudioToggle: true,
          renderPage1: true,
        }));
        break;

      case 1:
        setRenderState((state) => ({
          ...state,
          renderStartPage: false,
          renderPage1: true,
        }));
        break;

      case 2:
        setIsBgDark(true);
        setRenderState((state) => ({ ...state, renderPage2: true }));
        break;

      case 3:
        setScroll({
          immediate: false,
          scroll: vh + 125,
          onRest: () => {
            dispatch(updateRenderState({ page: 4 }));
          },
        });
        break;

      case 4:
        setRenderState((state) => ({ ...state, renderPage1: false }));
        setScroll({ scroll: 125, immediate: true, onRest: null });
        break;

      case 5:
        setRenderState((state) => ({ ...state, renderPage3: true }));
        break;

      case 6:
        setScroll({
          scroll: 1000,
          immediate: false,
          onRest: () => {
            dispatch(updateRenderState({ page: 7, question: 3 }));
          },
        });
        break;

      case 7:
        setRenderState((state) => ({ ...state, renderPage2: false }));
        setScroll({
          scroll: 0,
          immediate: true,
          onRest: null,
        });
        break;

      case 8:
        setRenderState((state) => ({ ...state, renderPhone: true }));
        break;

      case 9:
        window.removeEventListener(
          'orientationchange',
          preventScrollingOnPhoneRotate
        );

        raisePhone();
        break;

      case 10:
        setRenderState((state) => ({
          ...state,
          renderPage3: false,
          renderWindowPage: true,
          renderEasterEgg: false,
        }));
        break;

      case 11:
        dropPhone();
        break;

      case 12:
        setRenderState((state) => ({
          ...state,
          renderPhone: false,
        }));
        break;

      case 13:
        setRenderState((state) => ({
          ...state,
          renderPage3: false,
          renderResultPage: true,
        }));
        break;

      case 14:
        setRenderState((state) => ({
          ...state,
          renderEasterEgg: true,
        }));

        window.addEventListener(
          'orientationchange',
          preventScrollingOnPhoneRotate
        );
        break;

      case 15:
        window.removeEventListener(
          'orientationchange',
          preventScrollingOnPhoneRotate
        );

        setRenderState((state) => ({
          ...state,
          renderPhone: false,
        }));

        setScroll({
          immediate: false,
          scroll: 0,
          config: { duration: 2500, easing: (t) => easings.easeCubicOut(t) },
          onFrame: (props) => {
            window.scroll(0, props.scroll);
          },
          onRest: () => {
            dispatch(updateRenderState({ page: 16 }));
            setScroll({ onRest: null, onStart: null });
          },
          onStart: () => {
            audioElements[6].play();
          },
        });
        break;

      case 16:
        setRenderState((state) => ({
          ...state,
          renderPage3: false,
        }));
        break;

      case 17:
        setRenderState((state) => ({
          ...state,
          renderNamePage: true,
        }));
        break;

      case 18:
        setRenderState((state) => ({
          ...state,
          renderNamePage: false,
        }));
        break;

      case 19:
        setRenderState((state) => ({
          ...state,
          renderWindowPage: false,
          renderEasterEgg: false,
          renderAudioToggle: false,
        }));
        Howler.unload();
        break;
    }
  }, [page]);

  return {
    page,
    renderState,
    isBgDark,
    phoneSpring,
    scrollSpring,
    setScroll,
  };
};

export default useRenderState;
