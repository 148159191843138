import { SET_VIEWPORT } from '../actions/types';

const initialState = {
  width: 0,
  height: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VIEWPORT:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
