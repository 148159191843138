import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import './Phone.css';
import { FormattedMessage } from 'react-intl';
import { animated, useSpring, useTransition } from 'react-spring';
import { phoneAssets } from '../../assets';
import {
  initInvisible,
  initVisible,
  toInvisible,
  toVisible,
  initTransInvisible,
} from '../../animations';
import { updateRenderState, updateScore } from '../../actions';
import score from '../../score';
import { language } from '../../locale';

const {
  send,
  tools,
  messages,
  battery,
  toolbar,
  contentBg,
  signal,
  back,
  menu,
  statusBar,
  navBar,
  textInput,
  message,
  avatar,
  triangle,
  phone,
} = phoneAssets;

function Phone({ style }) {
  const dispatch = useDispatch();
  const replies = [...Array(4)];
  const canClick = useRef(true);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  const secondMsgTrans = useTransition(
    isMessageSending,
    null,
    initTransInvisible()
  );
  const inputToolbarTrans = useTransition(
    isMessageSent,
    null,
    initTransInvisible()
  );

  const [msgGroupSpring, setMsgGroupSpring] = useSpring(initVisible);
  const [lastMsgSpring, setLastMsgSpring] = useSpring(initInvisible);
  const [messageSpring, setMessageSpring] = useSpring(() => ({
    maxHeight: '70px',
  }));

  const showLastMessage = () => {
    setIsTyping(false);
    setLastMsgSpring(toVisible);
    setTimeout(() => {
      dispatch(updateRenderState({ page: 11 }));
    }, 3000);
  };

  const lastMessageHide = (onRest) => () => {
    setLastMsgSpring({
      delay: 6000,
      ...toInvisible,
      config: { duration: 200 },
      onRest,
    });
  };

  const lastMessageTyping = (onRest) => () => {
    setIsMessageSent(true);
    setLastMsgSpring({
      delay: 2000,
      ...toVisible,
      onRest,
    });
  };

  const sendSecondMessage = (onRest) => {
    setMsgGroupSpring(toInvisible);
    setTimeout(() => {
      setIsMessageSending(true);
    }, 300);
    setMessageSpring({
      to: { maxHeight: '6px' },
      onRest,
    });
  };

  const handleOptionClick = (index) => () => {
    if (!canClick.current) return;
    canClick.current = false;
    dispatch(updateScore(score.q7[index]));
    setSelectedOptionIndex(index + 1);

    sendSecondMessage(lastMessageTyping(lastMessageHide(showLastMessage)));
  };

  return (
    <animated.div className="Phone" style={style}>
      <img src={phone} alt="" />
      <div className="Phone__screen">
        <div
          className="Phone__status-bar"
          style={{ backgroundImage: `url(${statusBar})` }}
        >
          <div className="Phone__signal">
            <div className="Phone__signal-img">
              <img src={signal} alt="" />
            </div>
            <div className="Phone__signal-text">Forest</div>
          </div>

          <div className="Phone__status-time">6:32 PM</div>
          <div className="Phone__battery">
            <img src={battery} alt="" />
          </div>
        </div>
        <div
          className="Phone__nav-bar"
          style={{ backgroundImage: `url(${navBar})` }}
        >
          <div className="Phone__back">
            <img src={back} alt="" />
          </div>
          <div className="Phone__friend">
            <FormattedMessage id="phone_friend" />
          </div>
          <div className="Phone__menu">
            <img src={menu} alt="" />
          </div>
        </div>
        <div
          className="Phone__content"
          style={{ backgroundImage: `url(${contentBg})` }}
        >
          <div
            className="Phone__first-msg"
            style={{ backgroundImage: `url(${message})` }}
          >
            <div className="Phone__avatar">
              <img src={avatar} alt="" />
            </div>
            <div className="Phone__triangle">
              <img src={triangle} alt="" />
            </div>
            <FormattedMessage id="question_7" values={{ br: <br /> }} />
            <span className="Phone__time--right">6:31 PM</span>
          </div>

          {secondMsgTrans.map(
            ({ item, props, key }) =>
              item && (
                <React.Fragment key={key}>
                  <animated.div className="Phone__triangle--flip" style={props}>
                    <img src={triangle} alt="" />
                  </animated.div>
                  <animated.div
                    className={`Phone__second-msg ${language}`}
                    style={{ ...props, backgroundImage: `url(${message})` }}
                  >
                    <FormattedMessage
                      id={`question_7_option_${selectedOptionIndex}_reply`}
                      values={{ br: <br /> }}
                    />
                    <span className="Phone__time--left">6:32 PM</span>
                  </animated.div>
                </React.Fragment>
              )
          )}

          {isMessageSent && (
            <animated.div
              className="Phone__last-msg"
              style={{ ...lastMsgSpring, backgroundImage: `url(${message})` }}
            >
              <div className="Phone__avatar">
                <img src={avatar} alt="" />
              </div>
              <div className="Phone__triangle">
                <img src={triangle} alt="" />
              </div>
              {isTyping ? (
                // "Typing..."
                <div className="Phone__typing">
                  <div className="Phone__typing-dot--1" />
                  <div className="Phone__typing-dot--2" />
                  <div className="Phone__typing-dot--3" />
                </div>
              ) : (
                <FormattedMessage
                  id="question_7_friend_reply"
                  values={{ br: <br /> }}
                />
              )}
              <span className="Phone__time--right">6:32 PM</span>
            </animated.div>
          )}
        </div>
        <div
          className="Phone__bottom-bar"
          style={{ backgroundImage: `url(${toolbar})` }}
        >
          {inputToolbarTrans.map(
            ({ item, props, key }) =>
              item && (
                <animated.div
                  className="Phone__bottom-items"
                  key={key}
                  style={props}
                >
                  <div className="Phone__add">
                    +
                    <img src="" alt="" />
                  </div>
                  <div className="Phone__tools">
                    <img src={tools} alt="" />
                  </div>
                  <div
                    className="Phone__text-input"
                    style={{ backgroundImage: `url(${textInput})` }}
                  />
                </animated.div>
              )
          )}
          {!isMessageSent && (
            <animated.div
              className="Phone__msgs-group"
              style={{ ...msgGroupSpring, backgroundImage: `url(${messages})` }}
            >
              {replies.map((reply, index) => (
                <React.Fragment key={index}>
                  <animated.div
                    className="Phone__msg"
                    style={messageSpring}
                    onClick={handleOptionClick(index)}
                  >
                    <FormattedMessage
                      id={`question_7_option_${index + 1}`}
                      values={{ br: <br /> }}
                    />
                  </animated.div>
                  {index !== 3 && <div className="Phone__break-line"></div>}
                </React.Fragment>
              ))}
            </animated.div>
          )}
          <div className="Phone__send">
            <img src={send} alt="" />
          </div>
        </div>
      </div>
    </animated.div>
  );
}

export default Phone;
