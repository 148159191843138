import {
  UPDATE_CURRENT_AUDIO_INDEX,
  SET_AUDIO_ELEMENTS,
} from '../actions/types';

const initialState = {
  audioElements: null,
  currentAudioIndex: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AUDIO_ELEMENTS:
      return { ...state, audioElements: action.payload };
    case UPDATE_CURRENT_AUDIO_INDEX:
      return { ...state, currentAudioIndex: action.payload };
    default:
      return state;
  }
};
