import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useWindowSize } from 'react-use';
import { desktopAssets } from '../../assets';
import { language } from '../../locale';
import './Desktop.css';

const { phone, forestLogo, splitLine, background, qrcode } = desktopAssets;

function Desktop() {
  const { height: vh } = useWindowSize();

  useEffect(() => {
    const html = document.documentElement;
    html.style.setProperty('--vh', `${vh}px`);
  }, [vh]);

  return (
    <>
      <div
        className="Desktop"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="Desktop__content">
          <div className="Desktop__content-left">
            <div className="Desktop__phone">
              <img src={phone} alt="" />
            </div>
          </div>
          <div className="Desktop__content-right">
            <div className="Desktop__split-line">
              <img src={splitLine} alt="" />
            </div>
            <div className="Desktop__content-right-detail">
              <div className={`Desktop__title bold ${language}`}>
                <FormattedMessage id="desktop_title" />
              </div>
              <div className="Desktop__intro">
                <FormattedMessage id="desktop_intro" values={{ br: <br /> }} />
              </div>
              <div className="Desktop__content-right-bottom">
                <div className="Desktop__qrcode-container">
                  <img className="Desktop__qrcode" src={qrcode} alt="" />
                </div>
                <span className="Desktop__suggestion">
                  <FormattedMessage
                    id="desktop_suggestion"
                    values={{ br: <br /> }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="Desktop__forest-logo-container">
          <img src={forestLogo} alt="" />
        </div>
      </div>
    </>
  );
}

export default Desktop;
