import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { PRESSED_BTN_TEXT_COLORS } from './colors';

export const Dim = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const dialogBgStyles = css`
  background-image: url('${({ bg }) => bg}');
`;

export const Dialog = styled.div`
  ${dialogBgStyles}
  width: 270px;
  height: 270px;
  border-radius: 8px;
  padding: 9px 27px 27px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ bookmateIndex }) =>
    `var(--result-text-color-primary-${bookmateIndex + 1})`};
`;

export const PlantImage = styled.img`
  height: 66px;
  margin-top: 33px;
`;

export const SvgWrapper = styled.div`
  display: inline-block;
  height: fit-content;
  position: absolute;
  top: 12px;
  left: 14px;

  path {
    fill: ${({ color }) => color};
    stroke: ${({ color }) => color};
  }
`;

export const Text = styled.div`
  font-size: 13px;
  text-align: center;
  line-height: 20px;
  margin-top: auto;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 21px;
`;

export const Button = styled.div`
  width: 99px;
  height: 38px;
  line-height: 38px;
  font-size: 12px;
  position: relative;
  text-align: center;
  color: ${({ bookmateIndex }) =>
    `var(--result-text-color-primary-${bookmateIndex + 1})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('${({ releasedImage }) => releasedImage}');

  :active {
    background-image: url('${({ pressedImage }) => pressedImage}');
    color: ${({ bookmateIndex }) => PRESSED_BTN_TEXT_COLORS[bookmateIndex]};
  }
`;

export const Toast = styled(animated.div)`
  position: absolute;
  border-radius: 5px;
  height: 36px;
  line-height: 36px;
  width: 70px;
  text-align: center;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.7);
  /* background-color: #3c3c3c; */
  bottom: 80px;
  white-space: nowrap;
`;
