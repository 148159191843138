import React from 'react';
import { FormattedMessage } from 'react-intl';
import { orientPrompt } from '../../assets';
import './Prompt.css';

function Prompt({ style }) {
  return (
    <div className="Prompt" {...{ style }}>
      <FormattedMessage id="vertical_screen_warning" />
      <div className="Prompt__image">
        <img src={orientPrompt} alt="" />
      </div>
    </div>
  );
}

export default Prompt;
