import axios from 'axios';

export async function loadAsBase64(url) {
  const res = await axios
    .get(url, {
      responseType: 'blob',
    })
  const blob = res.data;
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return await new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
}