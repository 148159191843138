// render state
export const UPDATE_RENDER_STATE = 'UPDATE_RENDER_STATE';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const PAGE_DESTROYED = 'PAGE_DESTROYED';
// score
export const UPDATE_SCORE = 'UPDATE_SCORE';
// audio
export const UPDATE_CURRENT_AUDIO_INDEX = 'UPDATE_CURRENT_AUDIO_INDEX';
export const SET_AUDIO_ELEMENTS = 'SET_AUDIO_ELEMENTS';
// name
export const SET_NAME = 'SET_NAME';
// poster
export const POSTER_LOADED = 'POSTER_LOADED';
// token
export const SET_TOKEN = 'SET_TOKEN';
// orientation
export const SET_ORIENTATION = 'SET_ORIENTATION';

// viewport
export const SET_VIEWPORT = 'SET_VIEWPORT';
