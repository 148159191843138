import { allImagesAssets, desktopAssets, allLottieJsonPaths } from '../assets';
import { getLottieJson } from '../apis';
import { allFonts, commonFonts } from '../fonts';
import { Font } from './font-util';
import { language } from '../locale';
import { flattenDeep } from './helpers';

export const loadImage = (path) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve();
    img.onerror = (err) => reject(err);
    img.src = path;
  });

const loadAllImage = () => {
  const paths = flattenDeep(
    allImagesAssets.map((assetsObject) => Object.values(assetsObject))
  );

  return paths.map((path) => loadImage(path));
};

const loadDesktopImages = () => {
  const paths = Object.values(desktopAssets);

  return paths.map((path) => loadImage(path));
};

const fetchAllLottieJsonFiles = () => {
  const paths = flattenDeep(Object.values(allLottieJsonPaths));
  const getData = (path) => getLottieJson(path);

  return paths.map((path) => getData(path));
};

const loadFonts = () =>
  new Promise(async (resolve) => {
    const fontFaceSet = document.fonts;

    const fonts = allFonts[language]
      .map((font) => new Font(font))
      .concat(commonFonts);

    const fontFaces = fonts.map(
      (font) => new FontFace(font.family, font.url, font.descriptors)
    );

    await Promise.all(fontFaces.map((fontFace) => fontFace.load()));

    fontFaces.forEach((fontFace) => {
      fontFaceSet.add(fontFace);
    });

    resolve();
  });

export const loadAppAssets = () =>
  [].concat(loadAllImage(), fetchAllLottieJsonFiles(), loadFonts());

export const loadDesktopAssets = () =>
  [].concat(loadFonts(), loadDesktopImages());
