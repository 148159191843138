import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useWindowSize } from 'react-use';
import { setViewport } from '../../actions';

const useViewport = () => {
  const dispatch = useDispatch();
  const { width, height } = useWindowSize();

  useEffect(() => {
    dispatch(setViewport({ width, height }));
    const html = document.documentElement;
    html.style.setProperty('--vw', `${width}px`);
    html.style.setProperty('--vh', `${height}px`);
  }, [dispatch, width, height]);
};

export default useViewport;
