export const commonFonts = [
  {
    family: 'amatic',
    url:
      "url(/fonts/common/amatic.woff2) format('woff2'), url(/fonts/common/amatic.woff) format('woff')",
    descriptors: { style: 'normal', weight: '700' },
  },
];

export const allFonts = {
  en: [
    { family: 'en-400', style: 'normal', weight: '400' },
    { family: 'en-700', style: 'normal', weight: '700' },
  ],
  tc: [
    { family: 'tc-400', style: 'normal', weight: '400' },
    { family: 'tc-500', style: 'normal', weight: '500' },
  ],
  sc: [
    { family: 'sc-400', style: 'normal', weight: '400' },
    { family: 'sc-500', style: 'normal', weight: '500' },
  ],
  ko: [
    { family: 'ko-400', style: 'normal', weight: '400' },
    { family: 'ko-700', style: 'normal', weight: '700' },
  ],
  ja: [
    { family: 'ja-400', style: 'normal', weight: '400' },
    { family: 'ja-500', style: 'normal', weight: '500' },
  ],
  es: [
    { family: 'en-400', style: 'normal', weight: '400' },
    { family: 'en-700', style: 'normal', weight: '700' },
  ],
};
