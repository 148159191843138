import * as easings from 'd3-ease';
import html2canvas from 'html2canvas';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useSpring, useTransition } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { posterLoaded } from '../../actions';
import {
  initInvisible,
  initTransInvisible,
  initTransVisible,
  toVisible,
} from '../../animations';
import {
  bookmates,
  getPosterAssets,
  getResultAssets,
  resultAssets,
} from '../../assets';
import Arrow from '../../assets/images/result/arrow';
import { language } from '../../locale';
import { resultScore } from '../../score';
import { toStore } from '../../to-store';
import { ga } from '../../utils/analytics';
import { loadImage } from '../../utils/cache';
import { convertTextToImage } from '../../utils/canvas';
import { generateNameFont } from '../../utils/font-util';
import { loadAsBase64 } from '../../utils/get-base64';
import Forest from '../Forest/Forest';
import Modal from '../Modal/Modal';
import JaShareDialog from './JaShareDialog';
import './Result.css';

const { plant, msg } = resultAssets;
const testingId = 'B';

const Result = ({ page, setScroll }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const domRef = useRef(null);
  const posterRef = useRef(null);
  const flowerName = useRef(null);
  const longPressTimeRef = useRef(null);
  const score = useSelector((state) => state.scoreReducer);
  const { name } = useSelector((state) => state.nameReducer);
  const { isSpecialEnd } = useSelector((state) => state.renderStateReducer);
  const [isImageReady, setIsImageReady] = useState(false);
  const [posterImage, setPosterImage] = useState(null);
  const [resultIndex, setResultIndex] = useState(-1);
  const [showOverlay, setShowOverlay] = useState(true);
  const [ctaButtonToggle, setCtaButtonToggle] = useState(true);
  const overlayTrans = useTransition(showOverlay, null, initTransVisible(1000));
  const [renderModal, setRenderModal] = useState(false);
  const modalTrans = useTransition(renderModal, null, initTransInvisible());
  const [resultNameImage, setResultNameImage] = useState(null);
  const [flowerNameImage, setFlowerNameImage] = useState(null);
  const [ctaButton, setCtaButton] = useState('');
  const [pageSpring, setPageSpring] = useSpring(initInvisible);
  const [msgSpring, setMsgSpring] = useSpring(() => ({ opacity: 0 }));
  const hasEnteredForest = useRef(false);
  const [posterAssets, setPosterAssets] = useState(null);
  const [modalAssets, setModalAssets] = useState(null);
  const [promptClickSpring, setPromptClickSpring] = useSpring(() => ({
    opacity: 0,
  }));
  const [promptSpring, setPromptSpring] = useSpring(() => ({
    transform: `rotate(-16deg) translateX(40px)`,
  }));
  const ctaButtonTextSpring = useSpring({
    transform: `translate(-50%, ${ctaButtonToggle ? 0 : 3}px)`,
    immediate: true,
  });
  const [isPosterImagesLoaded, setIsPosterImagesLoaded] = useState({
    circles: false,
    flower: false,
    qrcode: false,
    logo: false,
    ...((language === 'en' || language === 'es') && {
      igIcon: false,
      twitterIcon: false,
    }),
  });
  const [canShowJaDialog, setCanShowJaDialog] = useState(false);
  const { height: vh } = useSelector((state) => state.viewportReducer);

  const showJaDialog = () => {
    ga.jaShareOpenDialog();
    setCanShowJaDialog(true);
  };

  const closeJaDialog = () => {
    setCanShowJaDialog(false);
  };

  const showModal = () => {
    ga.clickPal(testingId);
    setRenderModal(true);
  };

  const closeModal = () => {
    setRenderModal(false);
  };

  const handleCtaClick = () => {
    if (!hasEnteredForest.current) {
      ga.enterForestPage();
      hasEnteredForest.current = true;
    }

    ga.clickCta(testingId);

    setScroll({
      scroll: vh * 1.5,
      config: { duration: 1500, easing: (t) => easings.easeExpOut(t) },
      immediate: false,
    });
  };

  const handlePromptClick = () => {
    toStore(testingId, ga.clickPlantPrompt);
  };

  const handleLongPressOnPoster = ({ down }) => {
    if (down === true) {
      longPressTimeRef.current = Date.now();
    }

    if (down === false) {
      if (Date.now() - longPressTimeRef.current > 499) {
        ga.savePoster();
      }
    }
  };

  const bindDrag = useDrag((state) => handleLongPressOnPoster(state));

  useEffect(() => {
    setScroll({ scroll: 0, immediate: true });
    setInterval(() => {
      setCtaButtonToggle((state) => !state);
    }, 550);

    const sumUpScroe = () => {
      resultScore.forEach((result, index) => {
        if (
          result.split('').sort().join('') ===
          Object.keys(score)
            .map((name) => ({ name, value: score[name] }))
            .sort((a, b) => b.value - a.value)
            .slice(0, 4)
            .map((object) => object.name)
            .sort()
            .join('')
        ) {
          setResultIndex(index);
        }
      });
    };

    if (isSpecialEnd) {
      setResultIndex(16);
    } else {
      sumUpScroe();
    }
  }, []);

  useEffect(() => {
    if (resultIndex === -1) {
      return;
    }

    const loadNameFonts = async () => {
      if (name.length !== 0) {
        const font = await generateNameFont(name);
        document.fonts.add(font);
      }

      const fontSize = vh < 465 ? 20 : 23;
      const containerHeight = vh < 465 ? 29 : 33;

      if (name.length !== 0) {
        convertTextToImage({
          text: `${name}`,
          fontFace: `${fontSize}px "amatic", "name-font"`,
          fontSize,
          height: containerHeight,
          setImage: setResultNameImage,
          resultIndex,
        });
      } else {
        setResultNameImage('empty');
      }
    };

    const convertFlowerNameToImage = () => {
      flowerName.current = intl.formatMessage({
        id: `flower_meaning_${resultIndex + 1}_name`,
      });

      if (flowerName.current === null) {
        return;
      }

      const fontFace =
        language === 'en' || language === 'es'
          ? '34px "amatic"'
          : `26px "${language}-400"`;
      const boxHeight = language === 'en' || language === 'es' ? 43 : 37;
      const fontSize = language === 'en' || language === 'es' ? 34 : 26;

      convertTextToImage({
        text: flowerName.current,
        fontFace,
        fontSize,
        height: boxHeight,
        setImage: setFlowerNameImage,
        resultIndex,
      });
    };

    const loadPosterAssets = async () => {
      const posterAssetUrls = getPosterAssets(resultIndex);
      const modalAssets = getResultAssets(resultIndex);
      const posterAssetKeys = Object.keys(posterAssetUrls);
      const posterAssets = posterAssetKeys.map((key) => posterAssetUrls[key]);
      const posterAssetsBase64 = await Promise.all(
        posterAssets.map((url) => loadAsBase64(url))
      );
      const posterAssetsBase64Object = posterAssetKeys.reduce(
        (object, key, index) => ({
          ...object,
          [key]: posterAssetsBase64[index],
        }),
        {}
      );

      await Promise.all(
        Object.values(modalAssets).map((path) => loadImage(path))
      );
      setPosterAssets(posterAssetsBase64Object);
      setModalAssets(modalAssets);
    };

    const genColors = () => {
      const color = getComputedStyle(window.root).getPropertyValue(
        `--result-text-color-primary-${resultIndex + 1}`
      );
      const strokeColor = getComputedStyle(window.root).getPropertyValue(
        `--result-cta-color-${resultIndex + 1}`
      );
      window.root.style.setProperty('--twitter-prompt-color', color);
      window.root.style.setProperty(
        '--twitter-prompt-stroke-color',
        strokeColor
      );
    };

    loadNameFonts();
    convertFlowerNameToImage();
    loadPosterAssets();
    genColors();
  }, [resultIndex]);

  useEffect(() => {
    const checkImagesLoaded = () => {
      if (
        Object.values(isPosterImagesLoaded).some((value) => value === false)
      ) {
        return false;
      }

      return true;
    };

    if (
      resultNameImage === null ||
      flowerNameImage === null ||
      posterAssets === null ||
      checkImagesLoaded() === false
    ) {
      return;
    }

    const screenshot = async () => {
      try {
        const canvas = await html2canvas(posterRef.current);
        const image = canvas.toDataURL('image/jpeg', 1.0);
        setPosterImage(image);
        setIsImageReady(true);
        dispatch(posterLoaded());
      } catch (err) {
        console.log(err);
      }
    };

    screenshot();
  }, [resultNameImage, flowerNameImage, posterAssets, isPosterImagesLoaded]);

  useEffect(() => {
    if (!!modalAssets) {
      if (!!modalAssets.releasedCtaButton && !!modalAssets.pressedCtaButton) {
        ctaButtonToggle
          ? setCtaButton(modalAssets.releasedCtaButton)
          : setCtaButton(modalAssets.pressedCtaButton);
      }
    }
  }, [modalAssets, ctaButtonToggle]);

  useEffect(() => {
    const showPlantPrompt = () => {
      setPromptSpring({
        transform: `rotate(-16deg) translateX(0px)`,
        onRest: () => {
          setMsgSpring({
            opacity: 1,
            onRest: () => {
              setPromptClickSpring({
                to: async (next) => {
                  while (1) {
                    await next({ opacity: 1 });
                    await next({ opacity: 0.4 });
                  }
                },
                config: { duration: 1000 },
              });
            },
          });
        },
      });
    };

    if (page === 19) {
      ga.enterPoster(testingId);
      setShowOverlay(false);

      if (resultIndex !== 16) {
        setTimeout(showPlantPrompt, 2000);
      }
    }

    if (page === 13) {
      setPageSpring({
        ...toVisible,
        delay: 500,
      });
    }
  }, [page]);

  return (
    <>
      <animated.div
        ref={domRef}
        className="Result"
        style={{
          ...pageSpring,
          color: `var(--result-text-color-primary-${resultIndex + 1})`,
        }}
      >
        <div className="Result__poster-img" {...bindDrag()}>
          <img src={posterImage} alt="" />
        </div>

        {overlayTrans.map(
          ({ item, props, key }) =>
            item && (
              <animated.div
                className="Result__overlay"
                style={props}
                key={key}
              />
            )
        )}

        {modalTrans.map(
          ({ item, props, key }) =>
            item && (
              <Modal
                key={key}
                closeModal={closeModal}
                style={props}
                resultIndex={resultIndex}
                bookmateIndex={bookmates[resultIndex === -1 ? 0 : resultIndex]}
                flower={modalAssets.bookmateFlower}
                background={modalAssets.modalBg}
              />
            )
        )}

        {posterImage === null && <div className="background-invisible" />}

        <div
          className="Result__content"
          ref={posterRef}
          style={{
            backgroundImage: `url(${
              posterAssets ? posterAssets.background : ''
            })`,
          }}
        >
          <div
            className="Result__content-border"
            style={{
              borderColor: `var(--result-color-${resultIndex + 1})`,
            }}
          />

          <div
            className="Result__circle--1"
            style={{
              transform: `rotate(var(--result-circle-rotate-${
                resultIndex + 1
              }-1))`,
            }}
          >
            <img
              src={posterAssets ? posterAssets.circle : ''}
              alt=""
              onLoad={() => {
                setIsPosterImagesLoaded((state) => ({
                  ...state,
                  circles: true,
                }));
              }}
            />
          </div>
          <div
            className="Result__circle--2"
            style={{
              transform: `rotate(var(--result-circle-rotate-${
                resultIndex + 1
              }-2))`,
            }}
          >
            <img src={posterAssets ? posterAssets.circle : ''} alt="" />
          </div>
          <div
            className={`Result__circle--3 ${
              !isImageReady ? 'Result__circle--3-screenshot' : ''
            }`}
            style={{
              transform: `rotate(var(--result-circle-rotate-${
                resultIndex + 1
              }-3))`,
            }}
          >
            <img src={posterAssets ? posterAssets.circle : ''} alt="" />
          </div>
          <div
            className={`Result__circle--4 ${
              !isImageReady ? 'Result__circle--4-screenshot' : ''
            }`}
            style={{
              transform: `rotate(var(--result-circle-rotate-${
                resultIndex + 1
              }-4))`,
            }}
          >
            <img src={posterAssets ? posterAssets.circle : ''} alt="" />
          </div>

          <div className="Result__info">
            <div className="Result__content-top-row">
              <div
                className={`${
                  resultNameImage !== 'empty'
                    ? 'Result__name'
                    : 'Result__name--none'
                }`}
              >
                {resultNameImage !== 'empty' && (
                  <img src={resultNameImage} alt="" />
                )}
              </div>
            </div>

            <div className={`Result__image ${language}`}>
              <img
                src={posterAssets ? posterAssets.flower : ''}
                alt=""
                onLoad={() => {
                  setIsPosterImagesLoaded((state) => ({
                    ...state,
                    flower: true,
                  }));
                }}
              />
            </div>

            <div className="Result__middle-row">
              <div className={`Result__flower-name ${language}`}>
                <img src={flowerNameImage} alt="" />
              </div>

              <div className="Result__quote bold">
                {resultIndex !== -1 && (
                  <FormattedMessage
                    id={`flower_meaning_${resultIndex + 1}_quote`}
                  />
                )}
              </div>
            </div>

            <div
              className={`Result__description ${language}`}
              style={{
                color: `var(--result-text-color-secondary-${resultIndex + 1})`,
              }}
            >
              {resultIndex !== -1 && (
                <FormattedMessage
                  id={`flower_meaning_${resultIndex + 1}_description`}
                />
              )}
            </div>

            <div className={`Result__bottom-row ${language}`}>
              <div
                className="Result__bottom-col Result__bottom-col-pal"
                {...(language !== 'en' &&
                  language !== 'es' && {
                    onClick: showModal,
                  })}
              >
                {language !== 'en' && language !== 'es' && (
                  <div
                    className={`Result__arrow ${language} ${
                      renderModal ? 'hidden' : ''
                    }`}
                    data-html2canvas-ignore
                  >
                    <img src={posterAssets ? posterAssets.arrow : ''} alt="" />
                  </div>
                )}

                <div className="Result__pal-title bold">
                  <FormattedMessage id="poster_pal" />
                </div>
                <div
                  className="Result__pal"
                  style={{
                    color: `var(--result-text-color-secondary-${
                      resultIndex + 1
                    })`,
                  }}
                >
                  {resultIndex !== -1 && (
                    <FormattedMessage
                      id={`flower_meaning_${
                        bookmates[resultIndex === -1 ? 0 : resultIndex] + 1
                      }_name`}
                    />
                  )}
                </div>
              </div>

              {language === 'en' || language === 'es' ? (
                isImageReady ? (
                  <div className="Result__find-my-buddy" onClick={showModal}>
                    <FormattedMessage id="poster_direct_to_pal_dialog" />
                    <div className={`Result__arrow-container ${language}`}>
                      <Arrow />
                    </div>
                  </div>
                ) : (
                  <div className="Result__poster-find-buddy">
                    <FormattedMessage
                      id="poster_pal_dialog_caption_new"
                      values={{
                        bold: (
                          <span className="hashtag-bold">
                            <FormattedMessage id="poster_generate_pal_description_hashtag" />
                          </span>
                        ),
                      }}
                    />
                    <div className="Result__poster-icons">
                      <div className="Result__poster-icon">
                        <img
                          src={posterAssets ? posterAssets.twitterIcon : ''}
                          alt=""
                          onLoad={() => {
                            setIsPosterImagesLoaded((state) => ({
                              ...state,
                              twitterIcon: true,
                            }));
                          }}
                        />
                      </div>
                      <div className="Result__poster-icon">
                        <img
                          src={posterAssets ? posterAssets.igIcon : ''}
                          alt=""
                          onLoad={() => {
                            setIsPosterImagesLoaded((state) => ({
                              ...state,
                              igIcon: true,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="Result__bottom-col">
                  <div className="Result__place-title bold">
                    <FormattedMessage id="poster_place" />
                  </div>
                  <div
                    className="Result__place"
                    style={{
                      color: `var(--result-text-color-secondary-${
                        resultIndex + 1
                      })`,
                    }}
                  >
                    {resultIndex !== -1 && (
                      <FormattedMessage
                        id={`flower_meaning_${resultIndex + 1}_place`}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <animated.div
            className="Result__prompt"
            style={promptSpring}
            onClick={language === 'ja' ? showJaDialog : handlePromptClick}
          >
            <animated.div
              className={`Result__prompt-msg ${language}`}
              style={msgSpring}
            >
              <img src={msg} alt="" />
              <animated.div className={`Result__prompt-msg-text ${language}`}>
                <FormattedMessage
                  id={
                    language === 'ja'
                      ? 'share_link_plant_prompt_jp'
                      : testingId === 'A'
                      ? 'click_plant_prompt_v2'
                      : 'click_plant_prompt_v3'
                  }
                  values={{
                    br: <br />,
                  }}
                />
              </animated.div>
            </animated.div>
            <animated.div
              className={`Result__prompt-click-text ${language}`}
              style={promptClickSpring}
            >
              <FormattedMessage id="cta_secret_instruction" />
            </animated.div>
            <animated.div className="Result__plant">
              <img src={plant} alt="" />
            </animated.div>
          </animated.div>

          {isImageReady ? (
            <>
              <div className={`Result__save-image ${language}`}>
                <FormattedMessage
                  id="poster_save_description"
                  values={{ br: <br /> }}
                />
              </div>
              <div
                className={`${
                  resultIndex === 16 ? 'Result__footer--sp' : 'Result__footer'
                }`}
                style={{
                  backgroundColor: `var(--result-color-${resultIndex + 1})`,
                  color: `var(--result-cta-color-${resultIndex + 1})`,
                }}
              >
                <div
                  className={`Result__cta-logo-group ${language} ${
                    resultIndex === 16 ? 'sp' : ''
                  }`}
                  onClick={() => {
                    toStore(testingId, ga.clickLogoToStore);
                  }}
                >
                  <div className={`Result__cta-logo ${language}`}>
                    <img
                      src={posterAssets ? posterAssets.forestLogo : ''}
                      alt=""
                    />
                  </div>
                  <div className={`Result__cta-logo-text ${language}`}>
                    <FormattedMessage id="cta_forest_description" />
                  </div>
                </div>
                <div
                  className={`${
                    resultIndex === 16
                      ? 'Result__cta-button--sp'
                      : 'Result__cta-button'
                  }`}
                  onClick={handleCtaClick}
                >
                  <animated.div
                    className={`Result__cta-button-text ${language}`}
                    style={ctaButtonTextSpring}
                  >
                    <FormattedMessage
                      {...{
                        id: isSpecialEnd
                          ? 'redeem_now_button_for_starburst'
                          : 'redeem_now_button',
                      }}
                    />
                  </animated.div>
                  <img src={ctaButton} alt="" />
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="Result__qrcode-field"
                style={{
                  color: `var(--result-qrcode-color-${resultIndex + 1})`,
                }}
              >
                <div className="Result__qrcode-text--left">
                  <div>
                    <FormattedMessage id="poster_qrcode_text_left_1" />
                  </div>
                  <div>
                    <FormattedMessage id="poster_qrcode_text_left_2" />
                  </div>
                </div>
                <div className="Result__qrcode">
                  <img
                    src={posterAssets ? posterAssets.qrcode : ''}
                    alt=""
                    onLoad={() => {
                      setIsPosterImagesLoaded((state) => ({
                        ...state,
                        qrcode: true,
                      }));
                    }}
                  />
                </div>
                <div className="Result__qrcode-text--right">
                  <div>
                    <FormattedMessage id="poster_qrcode_text_right_1" />
                  </div>
                  <div>
                    <FormattedMessage id="poster_qrcode_text_right_2" />
                  </div>
                </div>
              </div>
              <div
                className="Result__footer-screenshot"
                style={{
                  backgroundColor: `var(--result-color-${resultIndex + 1})`,
                  color: `var(--result-cta-color-${resultIndex + 1})`,
                }}
              >
                <div className="Result__footer-screenshot-logo">
                  <img
                    src={posterAssets ? posterAssets.forestLogo : ''}
                    alt=""
                    onLoad={() => {
                      setIsPosterImagesLoaded((state) => ({
                        ...state,
                        logo: true,
                      }));
                    }}
                  />
                </div>
                <div className="Result__footer-screenshot-url">
                  <FormattedMessage id="poster_forest_description" />
                </div>
              </div>
            </>
          )}
        </div>
        {language === 'ja' && (
          <JaShareDialog
            canShow={canShowJaDialog}
            close={closeJaDialog}
            bgUrl={modalAssets?.modalBg}
            bookmateIndex={bookmates[resultIndex]}
            plantImage={modalAssets?.jaShareDialogPlant}
            releasedButtonImage={modalAssets?.releasedJaDialogButton}
            pressedButtonImage={modalAssets?.pressedJaDialogButton}
          />
        )}
      </animated.div>
      <Forest
        setScroll={setScroll}
        resultIndex={resultIndex}
        tree={modalAssets ? modalAssets.dialogTree : null}
      />
    </>
  );
};

export default Result;
