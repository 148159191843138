import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { animated } from 'react-spring';
import { setName, updateRenderState } from '../../actions';
import { nameAssets } from '../../assets';
import { language } from '../../locale';
import './Name.css';

const { submit } = nameAssets;

function Name({ style }) {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const canSubmit = useRef(true);

  const handleSubmit = () => {
    if (!canSubmit.current) {
      return;
    }

    canSubmit.current = false;
    inputRef.current.blur();
    window.scroll(0, 0);
    dispatch(updateRenderState({ page: 18 }));
  };

  // prevent iOS onFocus scrolling
  useEffect(() => {
    const scrollToTop = () => {
      window.scroll(0, 0);
    };

    window.addEventListener('scroll', scrollToTop);

    return () => {
      window.removeEventListener('scroll', scrollToTop);
    };
  }, []);

  return (
    <animated.div className="Name" style={style}>
      <div className={`Name__text ${language}`}>
        <FormattedMessage id="enter_name" values={{ br: <br /> }} />
      </div>
      <input
        ref={inputRef}
        className="Name__input"
        maxLength="10"
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            event.preventDefault();
            handleSubmit();
          }
        }}
        onInput={(event) => {
          dispatch(setName(event.target.value));
        }}
      />
      <div className="Name__submit" onClick={handleSubmit}>
        <div className={`Name__submit-text ${language}`}>
          <FormattedMessage id="enter_name_submit" />
        </div>
        <img src={submit} alt="" />
      </div>
    </animated.div>
  );
}

export default Name;
