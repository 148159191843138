import React, { useEffect, useState } from 'react';
import { useTransition } from 'react-spring';
import * as S from './styles';
import CloseIcon from '../../Modal/CloseIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import copy from '../../../utils/copy';
import { ga } from '../../../utils/analytics';

const JaShareDialog = ({
  close,
  canShow,
  bgUrl,
  bookmateIndex,
  releasedButtonImage,
  pressedButtonImage,
  plantImage,
}) => {
  const intl = useIntl();
  const [canShowToast, setCanShowToast] = useState(false);
  const [iconColor, setIconColor] = useState('#ffffff');

  const toastTrans = useTransition(canShowToast, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const jaDialogTrans = useTransition(canShow, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const showToast = () => {
    setCanShowToast(true);
  };

  const postOnTwitter = () => {
    const contentId = Math.floor(Math.random() * 2 + 2);
    ga.jaShareTweet(contentId);
    const message = intl.formatMessage({
      id: `share_link_dialog_placeholder_${contentId}`,
    });
    window.open(`https://twitter.com/intent/tweet?text=${message}`);
  };

  const handleCopyButtonClick = () => {
    ga.jaShareCopy();
    const text = intl
      .formatMessage({ id: 'share_link_dialog_copy_content' })
      .replace(/\\n/g, '\n');
    copy(text);
    showToast();
  };

  useEffect(() => {
    const color = getComputedStyle(window.root).getPropertyValue(
      `--result-text-color-primary-${bookmateIndex + 1}`
    );

    setIconColor(color);
  }, [bookmateIndex]);

  useEffect(() => {
    if (canShowToast === false) {
      return;
    }

    setTimeout(() => {
      setCanShowToast(false);
    }, 2000);
  }, [canShowToast]);

  return jaDialogTrans.map(
    ({ item, props, key }) =>
      item && (
        <S.Dim onTouchStart={() => {}} style={props} key={key}>
          <S.Dialog bg={bgUrl} bookmateIndex={bookmateIndex}>
            <S.SvgWrapper onClick={close} color={iconColor}>
              <CloseIcon />
            </S.SvgWrapper>
            <S.PlantImage src={plantImage} />
            <S.Text>
              <FormattedMessage
                id="share_link_dialog_description_jp"
                values={{
                  br: <br />,
                }}
              />
            </S.Text>
            <S.ButtonRow>
              <S.Button
                bookmateIndex={bookmateIndex}
                pressedImage={pressedButtonImage}
                releasedImage={releasedButtonImage}
                onClick={handleCopyButtonClick}
              >
                <FormattedMessage id="share_link_dialog_button_copy_jp" />
              </S.Button>

              <S.Button
                bookmateIndex={bookmateIndex}
                pressedImage={pressedButtonImage}
                releasedImage={releasedButtonImage}
                onClick={postOnTwitter}
              >
                <FormattedMessage id="share_link_dialog_button_share_jp" />
              </S.Button>
            </S.ButtonRow>
          </S.Dialog>
          {toastTrans.map(
            ({ item, props, key }) =>
              item && (
                <S.Toast key={key} style={props}>
                  コピー
                </S.Toast>
              )
          )}
        </S.Dim>
      )
  );
};

export default JaShareDialog;
