import lottie from 'lottie-web';

export const loadLottie = (container, path, loop = true, autoplay = false) =>
  lottie.loadAnimation({
    container,
    path,
    renderer: 'svg',
    autoplay,
    loop,
  });

export const loadLottieLocal = (
  container,
  animationData,
  loop = true,
  autoplay = false
) =>
  lottie.loadAnimation({
    container,
    animationData,
    renderer: 'svg',
    autoplay,
    loop,
  });
