import React from 'react';

export default () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 28 28"
  >
    <path
      d="M26.3,12.9L14.8,3.6c-0.2-0.1-0.4-0.1-0.5-0.1C14.1,3.6,14,3.8,14,4l0.2,3.3l0,0l0,0l0.2,3.1l-12.3-2c-0.1,0-0.3,0-0.4,0.1
	C1.6,8.7,1.5,8.9,1.5,9v10c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.4,0.1l6.6-1.1l0,0l0,0l5.7-1V23c0,0.2,0.1,0.4,0.3,0.5
	c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1l11.1-9.7c0.1-0.1,0.2-0.2,0.2-0.4S26.4,13,26.3,12.9z M15.4,10.2l-0.1-1.9l8,6.7l-1.4,1.2
	L15.4,10.2z M14.8,11.6c0.2,0,0.3,0,0.4-0.1c0,0,0,0,0,0l5.9,5.5l-1.6,1.4l-7.7-7.2L14.8,11.6z M3.6,9.8c0,0,0,0.1,0.1,0.1l7.9,7
	L9,17.3l-6.5-5.5V9.6L3.6,9.8z M2.5,16.1L4.7,18l-2.2,0.4V16.1z M6,17.8l-3.5-3v-1.7l5.2,4.4L6,17.8z M12.9,16.7
	C12.8,16.6,12.8,16.6,12.9,16.7L5.4,10l4.7,0.8c0,0,0,0,0,0.1l8.6,8.1l-1.4,1.2l-2-2v-1.4c0-0.1-0.1-0.3-0.2-0.4
	c-0.1-0.1-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0L12.9,16.7z M15.4,19.6l1.2,1.2l-1.2,1.1V19.6z M24,14.4l-8.8-7.4l-0.1-1.8l10.2,8.2
	L24,14.4z"
    />
  </svg>
);
