import { combineReducers } from 'redux';
import audioReducer from './audioReducer';
import nameReducer from './nameReducer';
import orientationReducer from './orientationReducer';
import posterReducer from './posterReducer';
import redeemReducer from './redeemReducer';
import renderStateReducer from './renderStateReducer';
import scoreReducer from './scoreReducer';
import viewportReducer from './viewportReducer';

export default combineReducers({
  renderStateReducer,
  scoreReducer,
  audioReducer,
  orientationReducer,
  redeemReducer,
  nameReducer,
  posterReducer,
  viewportReducer,
});
