import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import { initTransInvisible, initTransVisible } from '../../animations';
import { language } from '../../locale';
import { ga } from '../../utils/analytics';
import { loadAppAssets, loadDesktopAssets } from '../../utils/cache';
import { setGlobalLineHeight } from '../../utils/line-height';
import { disableDoubleTap, disableScroll } from '../../utils/locks';
import { load } from '../../utils/progress';
import Audio from '../Audio/Audio';
import Desktop from '../Desktop/Desktop';
import EasterEgg from '../EasterEgg/EasterEgg';
import Loader from '../Loader/Loader';
import Name from '../Name/Name';
import Page1 from '../Pages/Page1';
import Page2 from '../Pages/Page2';
import Page3 from '../Pages/Page3';
import StartPage from '../Pages/StartPage';
import WindowPage from '../Pages/WindowPage';
import Phone from '../Phone/Phone';
import Prompt from '../Prompt/Prompt';
import Result from '../Result/Result';
import './App.css';
import useGlobalOrientation from './useGlobalOrientation';
import useRenderState from './useRenderState';
import useViewport from './useViewport';

function App() {
  useViewport();
  useGlobalOrientation();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const { orientation } = useSelector((state) => state.orientationReducer);

  const {
    page,
    renderState,
    isBgDark,
    phoneSpring,
    scrollSpring,
    setScroll,
  } = useRenderState();

  const {
    renderAudioToggle,
    renderStartPage,
    renderPage1,
    renderPage2,
    renderPage3,
    renderPhone,
    renderWindowPage,
    renderEasterEgg,
    renderResultPage,
    renderNamePage,
  } = renderState;

  const loadingTransition = useTransition(
    isLoading,
    null,
    initTransVisible(600)
  );

  const NamePageTrans = useTransition(
    renderNamePage,
    null,
    initTransInvisible()
  );

  useEffect(() => {
    (function setTitleAndDescription() {
      document.title = intl.formatMessage({ id: 'short_title' });
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          'content',
          intl.formatMessage({ id: 'h5_event_description' })
        );
    })();

    (function cacheAssets() {
      const promises = isMobileOnly ? loadAppAssets() : loadDesktopAssets();
      load(promises, setLoadingProgress, setIsLoading);
    })();

    if (isMobileOnly) {
      ga.enterApp();
      disableScroll();
      disableDoubleTap();
      setGlobalLineHeight(language);
      // dispatch(getToken());
    }
  }, []);

  return (
    <>
      {loadingTransition.map(
        ({ item, key, props }) =>
          item && <Loader key={key} style={props} progress={loadingProgress} />
      )}
      {isMobileOnly ? (
        <>
          <Prompt style={{ ...(orientation === 0 && { display: 'none' }) }} />
          <animated.div
            className="App"
            style={{
              ...(orientation !== 0 && { display: 'none' }),
              ...scrollSpring,
              backgroundColor: `${isBgDark ? '#1a2042' : '#ffffff'}`,
            }}
          >
            {renderAudioToggle && <Audio />}
            {renderStartPage && <StartPage />}
            {renderPage1 && <Page1 />}
            {renderPage2 && <Page2 />}
            {renderEasterEgg && <EasterEgg setScroll={setScroll} />}
            {renderPage3 && <Page3 />}

            {renderPhone && <Phone style={phoneSpring} />}
            {renderWindowPage && <WindowPage page={page} />}
            {NamePageTrans.map(
              ({ item, key, props }) => item && <Name style={props} key={key} />
            )}
            {renderResultPage && <Result page={page} setScroll={setScroll} />}
          </animated.div>
        </>
      ) : (
        <Desktop />
      )}
    </>
  );
}

export default App;
