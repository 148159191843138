import { language, message } from '../locale';

const getText = (json) => {
  const chars = Object.values(json).join('').split('');
  const uniqChars = [...new Set(chars)];
  return uniqChars;
};

const toUnicodes = (charArray) =>
  charArray.map((char) => `U+0${char.charCodeAt(0).toString(16)}`).join(', ');

export const generateNameFont = (string) => {
  const chars = string.split('');
  const unicodeRange = toUnicodes(chars);
  const font = new FontFace('name-font', `url(/fonts/${language}/400.woff)`, {
    style: 'normal',
    weight: 'normal',
    unicodeRange,
  });

  return font.load();
};

export function Font({ family, style, weight }) {
  this.family = family;
  this.url = `url(/fonts/${language}/${weight}.woff2) format('woff2'), url(/fonts/${language}/${weight}.woff) format('woff')`;
  this.descriptors = { style, weight };
  this.unicodeRange = toUnicodes(getText(message));
}
