import * as easings from 'd3-ease';
import { config } from 'react-spring';

export const initInvisible = () => ({ from: { opacity: 0 } });

export const initVisible = () => ({ from: { opacity: 1 } });

export const toInvisible = { to: { opacity: 0 } };

export const toVisible = { to: { opacity: 1 } };

export const initTransVisible = (duration = null) => ({
  from: { opacity: 1 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
  config: !!duration ? { duration: duration } : config.default,
});

export const initTransInvisible = (duration = null) => ({
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
  config: !!duration ? { duration: duration } : config.default,
});

export const initLoadingTrans = {
  from: { opacity: 1 },
  leave: { opacity: 0 },
  enter: { opacity: 1 },
  config: { duration: 600 },
};

export const getPhoneSpringProps = () => ({
  from: { transform: `translate3d(-60px, 110%, 0) rotate(45deg)` },
  config: { duration: 1000, easing: (t) => easings.easeExpOut(t) },
});

export const fadeIn = (next = null) => ({
  from: { opacity: 0 },
  to: { opacity: 1 },
  config: { duration: 800 },
  onRest: () => {
    next && next();
  },
});

const getSeedParams = (index, vw, vh) =>
  [
    {
      x: -vw * 0.2,
      y: -vh * 0.65 + 90,
    },
    {
      x: vw * 0.2,
      y: -vh * 0.65 + 90,
    },
    {
      x: -vw * 0.2,
      y: -vh * 0.65 + 90 + 123,
    },
    {
      x: vw * 0.2,
      y: -vh * 0.65 + 90 + 123,
    },
  ][index];

export const initSeed = (index, vw, vh) => {
  return {
    opacity: 1,
    transform: `translate(${getSeedParams(index, vw, vh).x}px, ${
      getSeedParams(index, vw, vh).y
    }px)`,
  };
};

const moveToCenter = (index, vw, vh) => ({
  transform: `translate(0px,${getSeedParams(index, vw, vh).y}px)`,
});

const seedPlantIn = () => ({
  transform: `translate(0px,0px)`,
});

export const seedsFadeOut = (originIndex, toNextPage, vw, vh) => (index) => {
  return originIndex !== index
    ? {
        opacity: 0,
        config: { duration: 800 },
      }
    : {
        delay: 800,
        to: async (next) => {
          await next(moveToCenter(originIndex, vw, vh));
          await next(seedPlantIn());
        },
        config: { duration: 1500, easing: (t) => easings.easeCubicInOut(t) },
        onRest: toNextPage,
      };
};

export const getScrollSpringProps = () => ({
  immediate: false,
  scroll: 0,
  config: { duration: 1500, easing: (t) => easings.easeExpOut(t) },
  onFrame: (props) => {
    window.scroll(0, props.scroll);
  },
});

export const initScrollTransition = () => ({
  immediate: false,
  scroll: 0,
  onFrame: (props) => {
    window.scroll(0, props.scroll);
  },
  config: { duration: 1500, easing: (t) => easings.easeExpOut(t) },
});

export const initBlur = () => ({ filter: `blur(0px)` });

export const blur = (set) => ({
  to: { filter: `blur(3px)` },
  config: { duration: 2500, easing: (t) => easings.easeElastic(t) },
  onRest: () => {
    set({
      to: async (next) => {
        await next({ filter: `blur(2px)` });
        next({ filter: `blur(0px)` });
      },
      config: config.default,
      onRest: () => {},
    });
  },
});

export const eyeClose = {
  config: { duration: 800, easing: (t) => easings.easeExpOut(t) },
  to: async (next) => {
    await next({ maxHeight: '30%' });
    await next({ maxHeight: '20%' });
    await next({ maxHeight: '25%' });
    await next({ maxHeight: '0%' });
  },
};

export const initBooksMove = () => (index) => {
  const trans = [
    {
      x: -35,
      y: -76,
      r: 20,
    },
    { x: 4, y: -70, r: -8 },
    { x: -42, y: 10, r: 0 },
    { x: 6, y: -10, r: 12 },
  ];
  return {
    transform: `translate3d(${trans[index].x}%,${trans[index].y}%,0) rotate(${trans[index].r}deg) scale(1)`,
    config: { duration: 1200, easing: (t) => easings.easePolyOut(t, 4) },
  };
};

const bookMovePaths = [
  `-200%, -150%, 0`,
  `100%, -250%, 0`,
  `-200%, -20%, 0`,
  `100%, -15%, 0`,
];

export const booksMove = (originIndex, destroyLottie, toQuestion4) => (
  index
) => {
  return originIndex === index
    ? {
        to: async (next) => {
          await next({
            to: {
              transform: `translate3d(0%,0%,0) rotate(0deg) scale(1)`,
            },
            delay: 500,
            onRest: () => {
              toQuestion4();
            },
          });
          await next({
            to: {
              transform: `translate3d(0%,0%,0) rotate(0deg) scale(1)`,
            },
          });
        },
      }
    : {
        to: {
          transform: `translate3d(${bookMovePaths[index]}) rotate(0deg) scale(1)`,
        },
        onRest: () => {
          destroyLottie();
        },
      };
};

export const getPotMoveParams = (vh) => ({
  transform: `translate(50%, ${vh * 0.63 - 195 - 36}px) scale(0.9)`,
  config: { duration: 1500 },
});
