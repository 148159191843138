import {
  POSTER_LOADED,
  SET_AUDIO_ELEMENTS,
  SET_NAME,
  SET_ORIENTATION,
  SET_VIEWPORT,
  UPDATE_CURRENT_AUDIO_INDEX,
  UPDATE_PAGE,
  UPDATE_QUESTION,
  UPDATE_RENDER_STATE,
  UPDATE_SCORE
} from './types';

// export const getToken = () => async (dispatch) => {
//   try {
//     const response = await createSession();
//     const data = response.data;
//     dispatch({ type: SET_TOKEN, payload: data.token });
//   } catch (err) {
//     console.log(err);
//   }
// };

export const posterLoaded = () => ({
  type: POSTER_LOADED,
});

export const setName = (name) => ({
  type: SET_NAME,
  payload: name,
});

export const setAudioElements = (elements) => ({
  type: SET_AUDIO_ELEMENTS,
  payload: elements,
});

export const updateCurrentAudioIndex = (index) => ({
  type: UPDATE_CURRENT_AUDIO_INDEX,
  payload: index,
});

export const updateRenderState = (state) => ({
  type: UPDATE_RENDER_STATE,
  payload: state,
});

export const updatePage = (renderState) => ({
  type: UPDATE_PAGE,
  payload: renderState,
});

export const updateQuestion = (renderState) => ({
  type: UPDATE_QUESTION,
  payload: renderState,
});

export const updateScore = (score) => ({
  type: UPDATE_SCORE,
  payload: score,
});

export const setOrientation = (orientation) => ({
  type: SET_ORIENTATION,
  payload: orientation,
});

export const setViewport = (viewport) => ({
  type: SET_VIEWPORT,
  payload: viewport,
});
