import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import { updateCurrentAudioIndex, updateRenderState } from '../../actions';
import { initInvisible, toInvisible, toVisible } from '../../animations';
import { coverLottie, startPageAssets } from '../../assets';
import { language } from '../../locale';
import { ga } from '../../utils/analytics';
import { loadLottie } from '../../utils/lottie';
import './StartPage.css';

const { background, poweredBy } = startPageAssets;

const StartPage = () => {
  const { audioElements } = useSelector((state) => state.audioReducer);
  const [pageSpring, setPageSpring] = useSpring(initInvisible);
  const dispatch = useDispatch();
  const coverRef = useRef(null);

  useEffect(() => {
    setPageSpring(toVisible);
    coverRef.current && loadLottie(coverRef.current, coverLottie, true, true);
  }, []);

  const handleStartClick = (event) => {
    ga.clickCover();
    audioElements[0].play();
    dispatch(updateCurrentAudioIndex(0));
    event.stopPropagation();
    setPageSpring({
      ...toInvisible,
      onRest: () => {
        dispatch(updateRenderState({ page: 1 }));
      },
    });
  };

  return (
    <animated.div
      className="StartPage"
      style={{ ...pageSpring, backgroundImage: `url(${background})` }}
    >
      <div
        className="Cover__lottie"
        ref={coverRef}
        style={{
          marginTop:
            window.innerWidth / window.innerHeight > 0.625
              ? (window.innerHeight > window.outerHeight
                  ? window.outerHeight
                  : window.innerHeight) * -0.1
              : 0,
        }}
      />
      <div className="cover-content">
        <div className="Cover__content-row">
          <div className={`cover-intro bold ${language}`}>
            <FormattedMessage id="desktop_title" />
          </div>
          <div className={`Cover__description ${language}`}>
            <FormattedMessage id="h5_event_description" />
          </div>
        </div>

        <div className={`cover-start ${language}`} onClick={handleStartClick}>
          <FormattedMessage id="press_start" />
        </div>
      </div>
      <img src={poweredBy} className="Cover__powered-by" alt="" />
    </animated.div>
  );
};

export default StartPage;
