import { UPDATE_SCORE } from '../actions/types';

const initialState = {
  E: 0,
  I: 0,
  S: 0,
  N: 0,
  F: 0,
  T: 0,
  J: 0,
  P: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SCORE:
      return Object.assign(
        state,
        ...action.payload
          .split('')
          .map((score) => ({ [score]: state[score] + 1 }))
      );
    default:
      return state;
  }
};
