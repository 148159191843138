import { language } from '../locale';
import { ver } from '../version';
import ball from './images/dialog/ball.svg';
import close from './images/dialog/close.svg';
import submit from './images/name/submit.png';
import poweredBy from './images/powered_by.png';
import msg from './images/result/msg.svg';
import plant from './images/result/plant.svg';

const baseUrl =
  ver === 'global'
    ? 'https://forest-mt-s3.seekrtech.com/assets'
    : 'https://upwardsware-forest-quiz-h5.s3.cn-northwest-1.amazonaws.com.cn/assets';

export const orientPrompt = `${baseUrl}/images/prompt.jpg`;

export const desktopAssets = {
  background: `${baseUrl}/images/desktop/pattern.jpg`,
  phone: `${baseUrl}/images/desktop/phone_${language}.png`,
  splitLine: `${baseUrl}/images/desktop/split_line.png`,
  forestLogo: `${baseUrl}/images/desktop/forest_logo.svg`,
  qrcode: `${baseUrl}/images/desktop/qrcode_${
    ver === 'global' ? 'global' : 'cn'
  }.svg`,
};

export const audioAssets = {
  unmutedButton: `${baseUrl}/images/audio/unmuted.svg`,
  mutedButton: `${baseUrl}/images/audio/muted.svg`,
};

export const startPageAssets = {
  background: `${baseUrl}/images/cover/background.jpg`,
  poweredBy,
};

export const page1_Assets = {
  background: `${baseUrl}/images/page_1/background.jpg`,
  pot: `${baseUrl}/images/page_1/pot.png`,
  mud: `${baseUrl}/images/page_1/mud.png`,
  cloud: `${baseUrl}/images/page_1/cloud.png`,
  seeds: [
    `${baseUrl}/images/page_1/seeds/1.png`,
    `${baseUrl}/images/page_1/seeds/2.png`,
    `${baseUrl}/images/page_1/seeds/3.png`,
    `${baseUrl}/images/page_1/seeds/4.png`,
  ],
  seedShadows: [
    `${baseUrl}/images/page_1/seeds/1s.png`,
    `${baseUrl}/images/page_1/seeds/2s.png`,
    `${baseUrl}/images/page_1/seeds/3s.png`,
    `${baseUrl}/images/page_1/seeds/4s.png`,
  ],
};

export const page2_Assets = {
  background: `${baseUrl}/images/page_2/background.jpg`,
};

export const page3_Assets = {
  background: `${baseUrl}/images/page_3/background.jpg`,
  windowLight: `${baseUrl}/images/page_3/window_light.png`,
};

export const phoneAssets = {
  send: `${baseUrl}/images/phone/send.png`,
  tools: `${baseUrl}/images/phone/tools.png`,
  battery: `${baseUrl}/images/phone/battery.png`,
  messages: `${baseUrl}/images/phone/messages.jpg`,
  toolbar: `${baseUrl}/images/phone/toolbar.jpg`,
  statusBar: `${baseUrl}/images/phone/status_bar.jpg`,
  navBar: `${baseUrl}/images/phone/nav_bar.jpg`,
  contentBg: `${baseUrl}/images/phone/content_bg.jpg`,
  signal: `${baseUrl}/images/phone/signal.png`,
  back: `${baseUrl}/images/phone/back.png`,
  menu: `${baseUrl}/images/phone/menu.png`,
  textInput: `${baseUrl}/images/phone/text_input.jpg`,
  message: `${baseUrl}/images/phone/message.jpg`,
  avatar: `${baseUrl}/images/phone/avatar.png`,
  triangle: `${baseUrl}/images/phone/triangle.png`,
  phone: `${baseUrl}/images/phone/phone.png`,
};

export const windowPageAssets = {
  windowBg: `${baseUrl}/images/window/window.png`,
  background: `${baseUrl}/images/window/background.jpg`,
  moon: `${baseUrl}/images/window/moon.png`,
  stars: `${baseUrl}/images/window/stars.png`,
  potShadow: `${baseUrl}/images/window/pot_shadow.png`,
  wall: `${baseUrl}/images/window/wall.jpg`,
};

export const nameAssets = {
  submit,
};

export const forestAssets = {
  back: `${baseUrl}/images/forest/back.svg`,
  logo: `${baseUrl}/images/forest/logo.png`,
  cards: [...Array(6)].map(
    (n, index) => `${baseUrl}/images/forest/cards/${index + 1}.jpg`
  ),
};

export const resultAssets = {
  plant,
  msg,
};

export const bookmates = [
  9,
  10,
  11,
  8,
  15,
  14,
  13,
  12,
  3,
  0,
  1,
  2,
  7,
  6,
  5,
  4,
  16,
];

export const getPosterAssets = (index) => ({
  circle: `${baseUrl}/images/result/circles/${index + 1}.jpg`,
  background: `${baseUrl}/images/result/backgrounds/${index + 1}.jpg`,
  flower: `${baseUrl}/images/result/flowers/${index + 1}.png`,
  forestLogo: `${baseUrl}/images/result/forest_logos/${index + 1}.png`,
  arrow: `${baseUrl}/images/result/arrows/${index + 1}.png`,
  qrcode: `${baseUrl}/images/result/qrcodes/${
    ver === 'global' ? 'global' : 'cn'
  }/${index + 1}.svg`,
  twitterIcon: `/images/icons/twitter/${index + 1}@2x.png`,
  igIcon: `/images/icons/ig/${index + 1}@2x.png`,
});

export const getResultAssets = (index) => ({
  bookmateFlower: `${baseUrl}/images/result/flowers/${
    bookmates[index] + 1
  }.png`,
  jaShareDialogPlant: `${baseUrl}/images/ja-share-dialog/plant.png`,
  modalBg: `${baseUrl}/images/modal/backgrounds/${bookmates[index] + 1}.jpg`,
  dialogTree: `${baseUrl}/images/dialog/trees/${index + 1}.png`,
  releasedCtaButton: `${baseUrl}/images/result/cta_buttons/released/${
    index + 1
  }.jpg`,
  pressedCtaButton: `${baseUrl}/images/result/cta_buttons/pressed/${
    index + 1
  }.jpg`,
  releasedJaDialogButton: `${baseUrl}/images/ja-share-dialog/buttons/released/${
    bookmates[index] + 1
  }.png`,
  pressedJaDialogButton: `${baseUrl}/images/ja-share-dialog/buttons/pressed/${
    bookmates[index] + 1
  }.png`,
});

export const dialogAssets = {
  close,
  ball,
};

export const easterEggAssets = {
  player: `${baseUrl}/images/easter_egg/player.png`,
  playerBg: `${baseUrl}/images/easter_egg/player_bg.jpg`,
};

export const resultBackgroundsIndex = [
  0,
  1,
  2,
  4,
  3,
  1,
  5,
  3,
  0,
  5,
  3,
  3,
  4,
  4,
  1,
  5,
];

///////////////
//// Audio ////
///////////////

export const audioSources = [
  `${baseUrl}/audio/0.mp3`,
  `${baseUrl}/audio/1.mp3`,
  `${baseUrl}/audio/2.mp3`,
  `${baseUrl}/audio/3.mp3`,
  `${baseUrl}/audio/4.mp3`,
  `${baseUrl}/audio/message.wav`,
  `${baseUrl}/audio/ending.mp3`,
];

/////////////////
//// Lotties ////
/////////////////

export const coverLottie = `${baseUrl}/lotties/cover.json`;

export const playerLottie = `${baseUrl}/lotties/player.json`;

export const bookLotties = [...Array(4)].map(
  (n, index) => `${baseUrl}/lotties/books/${language}/${index + 1}.json`
);

export const phoneLottie = `${baseUrl}/lotties/phone.json`;

export const memoLottie = `${baseUrl}/lotties/memo.json`;

export const notebookLottie = `${baseUrl}/lotties/notebook.json`;

export const fireworksLottie = `${baseUrl}/lotties/fireworks.json`;

export const plantLottie = `${baseUrl}/lotties/plant.json`;

export const starsLottie = `${baseUrl}/lotties/stars.json`;

///////////////
////  All  ////
///////////////

export const allLottieJsonPaths = {
  coverLottie,
  playerLottie,
  bookLotties,
  phoneLottie,
  memoLottie,
  notebookLottie,
  fireworksLottie,
  plantLottie,
  starsLottie,
};

export const allImagesAssets = [
  audioAssets,
  startPageAssets,
  page1_Assets,
  page2_Assets,
  page3_Assets,
  phoneAssets,
  windowPageAssets,
  easterEggAssets,
  resultAssets,
  forestAssets,
  nameAssets,
  dialogAssets,
];
